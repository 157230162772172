import React, { useState } from 'react'

const UserRole = ({ row }) => {
    const [selectedUserRole, setSelectedUserRole] = useState(row.user_role);
  
    const handleUserRoleChange = (event) => {
      setSelectedUserRole(event.target.value);
      
      console.log('Selected user role:', event.target.value);
    };
  
    return (
      <div className="userrole">
        <div className="form-control-select">
          <select
            className="form-control"
            id={`user_role_${row.id}`}
            value={selectedUserRole}
            onChange={handleUserRoleChange}
          >
            <option id="1" value="1">Normal</option>
            <option id="2" value="2">Admin</option>
            <option id="4" value="4">Services Agent</option>
            <option id="5" value="5">Accounting Manager</option>
            <option id="6" value="6">Sales Manager</option>
            <option id="7" value="7">Call Center Staff</option>
            <option id="8" value="8">Logistics manager</option>
            <option id="9" value="9">System User</option>
            <option id="10" value="10">Miner</option>
            <option id="11" value="11">Developer</option>
            <option id="12" value="12">System Miner</option>
            <option id="13" value="13">Doctor</option>
          </select>
        </div>
      </div>
    );
  };
  
  export default UserRole;

