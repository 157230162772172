import React, { useState } from "react";
import Content from "../../../layout/content/Content";
// import "../../assets/css/AddUser.css";
import "../../../assets/css/AddUser.css";
import addCoin from "../../../images/Add Coin White.png";
import newsPNG from "../../../images/News.png";
import kycverify from "../../../images/kycverify.png";

import reviewImg from "../../../assets/images/Review.png";
import reportImg from "../../../images/reports.png";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { Link } from "react-router-dom";
const AdminManageCoinExchange = () => {
  const [file, setFile] = useState("");

  return (
    <React.Fragment>
      <Head title="Basic Tables" />
      <Content page="component">
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3">Admin Manage CoinExchange</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            {/* <div class="card card-bordered card-preview">
                    <div class="card-inner"> */}
            <div class="row g-gs">
              <div className="col-sm-4 card">
                <Link to="/admin-manage-coinexchange/coinList" className="card-link">
                  <div className="card-content text-center">
                    <img src={addCoin} className="w-30 m-3" alt="" />
                    {/* <svg className="w-25 m-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                                            <rect x="10" y="10" width="50" height="40" rx="7" ry="7" fill="#e3e7fe" stroke="#6576ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></rect>
                                            <rect x="25" y="27" width="50" height="40" rx="7" ry="7" fill="#fff" stroke="#6576ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></rect>
                                            <path d="M47.4,52.58S37.23,62.76,31.63,56.16c-4.88-5.76-5.13-11.09-1.41-14.81s11.53-3.87,15.92,1.19,10,10.79,12.49,12.35,11.83,2.75,13.62-5.36-5.13-9.3-7.59-9.67c-.69-.1-2.27,1-4.39,2.29C54.93,45.42,47.4,52.58,47.4,52.58Z" fill="#e3e7fe" fillRule="evenodd"></path>
                                            <path d="M44.66,41.42a11,11,0,0,0-4.81-2.78,10.12,10.12,0,1,0,0,19.72,11,11,0,0,0,4.81-2.78q1.58-1.45,3.1-2.94l-.2-.19c-1,1-2.05,2-3.08,2.93a10.65,10.65,0,0,1-4.7,2.71,9.84,9.84,0,1,1,0-19.18,10.65,10.65,0,0,1,4.7,2.71c4.52,4.22,8.85,8.64,13.38,12.86A9.48,9.48,0,0,0,62,56.89a8.61,8.61,0,1,0,0-16.78,9.48,9.48,0,0,0-4.11,2.41c-1,.95-2,1.91-3,2.88l.2.19,3-2.87a9.3,9.3,0,0,1,4-2.34,8.34,8.34,0,1,1,0,16.24,9.3,9.3,0,0,1-4-2.34c-4.52-4.22-8.85-8.65-13.38-12.86Z" fill="#6576ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fillRule="evenodd"></path>
                                        </svg> */}
                    <div className="card-info ms-5">
                      <h5>Add Coin</h5>
                      <p>Add, Edit, Delete and Manage Coin Here.</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-sm-4 card" style={{ borderLeft: "1px solid black" }}>
                <Link to="/admin-manage-coinexchange/newsList" className="card-link">
                  <div className="card-content text-center">
                    <img src={newsPNG} className="w-30 m-3" alt="" />

                    {/* <svg class="w-25 m-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 113.9">
                                            <path d="M87.84,110.34l-48.31-7.86a3.55,3.55,0,0,1-3.1-4L48.63,29a3.66,3.66,0,0,1,4.29-2.8L101.24,34a3.56,3.56,0,0,1,3.09,4l-12.2,69.52A3.66,3.66,0,0,1,87.84,110.34Z" transform="translate(-4 -2.1)" fill="#c4cefe"></path>
                                            <path d="M33.73,105.39,78.66,98.1a3.41,3.41,0,0,0,2.84-3.94L69.4,25.05a3.5,3.5,0,0,0-4-2.82L20.44,29.51a3.41,3.41,0,0,0-2.84,3.94l12.1,69.11A3.52,3.52,0,0,0,33.73,105.39Z" transform="translate(-4 -2.1)" fill="#c4cefe"></path>
                                            <rect x="22" y="17.9" width="66" height="88" rx="3" ry="3" fill="#6576ff"></rect>
                                            <rect x="31" y="85.9" width="48" height="10" rx="1.5" ry="1.5" fill="#fff"></rect>
                                            <rect x="31" y="27.9" width="48" height="5" rx="1" ry="1" fill="#e3e7fe"></rect>
                                            <rect x="31" y="37.9" width="23" height="3" rx="1" ry="1" fill="#c4cefe"></rect>
                                            <rect x="59" y="37.9" width="20" height="3" rx="1" ry="1" fill="#c4cefe"></rect>
                                            <rect x="31" y="45.9" width="23" height="3" rx="1" ry="1" fill="#c4cefe"></rect>
                                            <rect x="59" y="45.9" width="20" height="3" rx="1" ry="1" fill="#c4cefe"></rect>
                                            <rect x="31" y="52.9" width="48" height="3" rx="1" ry="1" fill="#e3e7fe"></rect>
                                            <rect x="31" y="60.9" width="23" height="3" rx="1" ry="1" fill="#c4cefe"></rect>
                                            <path d="M98.5,116a.5.5,0,0,1-.5-.5V114H96.5a.5.5,0,0,1,0-1H98v-1.5a.5.5,0,0,1,1,0V113h1.5a.5.5,0,0,1,0,1H99v1.5A.5.5,0,0,1,98.5,116Z" transform="translate(-4 -2.1)" fill="#9cabff"></path>
                                            <path d="M16.5,85a.5.5,0,0,1-.5-.5V83H14.5a.5.5,0,0,1,0-1H16V80.5a.5.5,0,0,1,1,0V82h1.5a.5.5,0,0,1,0,1H17v1.5A.5.5,0,0,1,16.5,85Z" transform="translate(-4 -2.1)" fill="#9cabff"></path>
                                            <path d="M7,13a3,3,0,1,1,3-3A3,3,0,0,1,7,13ZM7,8a2,2,0,1,0,2,2A2,2,0,0,0,7,8Z" transform="translate(-4 -2.1)" fill="#9cabff"></path>
                                            <path d="M113.5,71a4.5,4.5,0,1,1,4.5-4.5A4.51,4.51,0,0,1,113.5,71Zm0-8a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,113.5,63Z" transform="translate(-4 -2.1)" fill="#9cabff"></path>
                                            <path d="M107.66,7.05A5.66,5.66,0,0,0,103.57,3,47.45,47.45,0,0,0,85.48,3h0A5.66,5.66,0,0,0,81.4,7.06a47.51,47.51,0,0,0,0,18.1,5.67,5.67,0,0,0,4.08,4.07,47.57,47.57,0,0,0,9,.87,47.78,47.78,0,0,0,9.06-.87,5.66,5.66,0,0,0,4.08-4.09A47.45,47.45,0,0,0,107.66,7.05Z" transform="translate(-4 -2.1)" fill="#2ec98a"></path>
                                            <path d="M100.66,12.81l-1.35,1.47c-1.9,2.06-3.88,4.21-5.77,6.3a1.29,1.29,0,0,1-1,.42h0a1.27,1.27,0,0,1-1-.42c-1.09-1.2-2.19-2.39-3.28-3.56a1.29,1.29,0,0,1,1.88-1.76c.78.84,1.57,1.68,2.35,2.54,1.6-1.76,3.25-3.55,4.83-5.27l1.35-1.46a1.29,1.29,0,0,1,1.9,1.74Z" transform="translate(-4 -2.1)" fill="#fff"></path>
                                        </svg> */}
                    <div className="card-info ms-3">
                      <h5>CoinExchange Company News</h5>
                      <p>Manage news articles and announcements for coin exchange here.</p>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-4 card" style={{ borderLeft: "1px solid black" }}>
                <Link to="/admin-manage-coinexchange/reviewsList" className="card-link">
                  <div className="card-content text-center">
                    <img src={reviewImg} className="w-30 ms-3 mb-2" alt="" />
                    <div className="card-info ms-5">
                      <h5>Reviews</h5>
                      <p>Manage Coin reviews Here.</p>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-4 card mt-15">
                <Link to="/admin-manage-coinexchange/user-kyc-data" className="card-link">
                  <div className="card-content text-center">
                    <img src={kycverify} className="w-30 ms-3 mb-2" alt="" />
                    <div className="card-info ms-5">
                      <h5>KYC Verification</h5>
                      <p>Verify the user KYC Here.</p>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-sm-4 card" style={{ borderLeft: "1px solid black" }}>
                <Link to="/admin-manage-coinexchange/coinReports" className="card-link">
                  <div className="card-content text-center">
                    <img src={reportImg} className="w-30 ms-3 mb-2" alt="" />
                    <div className="card-info ms-5">
                      <h5>Reports</h5>
                      <p>Manage Coin reports Here.</p>
                    </div>
                  </div>
                </Link>
              </div>

              {/* </div>
                    </div> */}
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AdminManageCoinExchange;
