const menu = [
  {
    heading: "Dashboards",
  },
  // {
  //   icon: "dashboard",
  //   text: "Default Dashboard",
  //   link: "/",
  //   dashboard: true,
  // },
  // {
  //   icon: "user-list",
  //   text: "Manage Users",
  //   link: "/user-list",
  //   dashboard: true,
  // },
  // {
  //   icon: "package",
  //   text: "Admin Manage CMS",
  //   link: "/admin-manage-cms",
  //   dashboard: true,
  // },
  {
    icon: "coin",
    text: "Admin Manage CoinExchange",
    link: "/admin-manage-coinexchange",
    dashboard: true,
  },
  {
    icon: "user",
    text: "Admin Manage Silosecuredata",
    link: "/admin-manage-contact",
    dashboard: true,
  },
  // {
  //   icon: "setting",
  //   text: "Admin Site Settings",
  //   link: "/site-settings-dashboard",
  //   dashboard: true,
  // },  

];
export default menu;
