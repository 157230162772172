import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import {  Label, Input, Row, Col } from "reactstrap";
import {
    Block,
    PreviewCard,
    Icon,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";

const PersonalInformation = () => {
    useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await fetch("https://api.silocloud.io/api/v1/public/countries", { method: "GET" });
            if (!response.ok) {
              throw new Error('Failed to fetch countries');
            }
            const data = await response.json();
            //setCountries(data.data.countries);
            
          } catch (error) {
            console.error('Error fetching countries:', error);
          }
        };
    
        fetchCountries();
      }, []);


    const [countries, setCountries] = useState([]);
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
   

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }

    
  return (
    <Block size="lg">
        <PreviewCard>
            <div className="card-head">
                <h5 className="card-title">Personal Information</h5>
            </div>
            <form action="#">
                <Row className="g-4">
                    <Col lg="6" className="">
                        <div className="form-group">
                            <Label className="form-label" htmlFor="firstname">First Name</Label>
                            <Input id="firstname" value="TempUser2" type="text" className="form-control focus"/>
                        </div>
                    </Col>
                    <Col lg="6" className="">
                        <div class="form-group">
                            <Label class="form-label" htmlFor="lastname">Last Name</Label>
                            <Input id="lastname" value="" type="text" class="form-control focus" />
                        </div>
                    </Col>
                    <Col lg="6" className="">
                        <div className="form-group">
                            <Label className="form-label" htmlFor="occupation">Occupation</Label>
                            <select className="form-control form-select mt-0" id="occupation" name="userState" data-placeholder="Select a country" data-search="on">
                                <option value="2">Web Developer</option>
                                <option value="3">Doctor</option>
                                <option value="4">Advocate</option>
                                <option value="5">Travel Agent</option>
                                <option value="6">Telecommunications, Tech.   </option>
                                <option value="7">Telecom </option>
                                <option value="8">Technology Developer</option>
                                <option value="9">Technician</option>
                                <option value="10">Tech</option>
                                <option value="11">Super Producer</option>
                                <option value="12">Student</option>
                                <option value="13">Software Developer</option>
                                <option value="14">Small Business Consultant / Community Educator</option>
                                <option value="15">Sales Execution And Customer Experience</option>
                                <option value="16">sales </option>
                                <option value="17">Retail </option>
                                <option value="18">producer</option>
                                <option value="19">President</option>
                                <option value="20">Outside Sales</option>
                                <option value="21">Noitavonne Business Development</option>
                                <option value="22">Musical Artist</option>
                                <option value="23">Multifamily Real Estate And Business Development Consultant</option>
                                <option value="24">Medical Dispesary </option>
                                <option value="25">Media and Entertainment</option>
                                <option value="26">Marketing Consultant</option>
                                <option value="27">Marketing And Media</option>
                                <option value="28">Managment</option>
                                <option value="29">Manager</option>
                                <option value="30">LAUGH SKILLET MEDIA ENTERTAINMENT</option>
                                <option value="31">IT</option>
                                <option value="32">Graphic Designer</option>
                                <option value="33">Graphic Design</option>
                                <option value="34">General Manager</option>
                                <option value="35">Fire Council </option>
                                <option value="36">Facillitator, Hyphenate-Artist, Producer, Novelest; Light-Worker</option>
                                <option value="37">Exectutive Sales Consultant</option>
                                <option value="38">Entrepreneur, Educator and Recruiting Consultant</option>
                                <option value="39">engineer</option>
                                <option value="40">Educator</option>
                                <option value="41">Director</option>
                                <option value="42">Customer Service</option>
                                <option value="43">Creative</option>
                                <option value="44">COO</option>
                                <option value="45">Consultant Host</option>
                                <option value="46">Consultant</option>
                                <option value="47">Concert Promoter</option>
                                <option value="48">Cinematographer </option>
                                <option value="49">Chief Governing Officer </option>
                                <option value="50">Ceo</option>
                                <option value="51">Business woman</option>
                                <option value="52">Business Owner, DJ, Creative</option>
                                <option value="53">Business Owner / Investor </option>
                                <option value="54">Business Manager and Agent</option>
                                <option value="55">Business Development Director</option>
                                <option value="56">Business Development</option>
                                <option value="57">Business</option>
                                <option value="58">Beauty Influencer</option>
                                <option value="59">Automatic Retail Solutions </option>
                                <option value="60">Automated Vending Kiosk</option>
                                <option value="61">Author, Investor </option>
                                <option value="62">Audio </option>
                                <option value="63">Artist </option>
                                <option value="64">Around The Clock llc (President) Talent Manager &amp; Economic Strategist</option>
                                <option value="65">ACTOR,ARTIST, MGR, MARKETING ,HOST</option>
                                <option value="66">aCARDIOz.com</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg="6" className="">
                        <div className="form-group">
                            <Label className="form-label" htmlFor="company_name">Company</Label>
                            <Input id="company_name" value="" type="text" className="form-control focus"/>
                        </div>
                    </Col>
                    <Col lg="6" className="">
                        <div className="form-group">
                            <Label htmlFor="country" className="form-label">
                            Country
                            </Label>
                            <div className="form-control-wrap">
                                <div className="form-control-select">
                                <Input type="select" name="select" id="country">
                                {countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                    {country.name}
                                    </option>
                                ))}
                                </Input>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" class="">
                        <div class="form-group">
                            <Label class="form-label" htmlFor="userState">State</Label>
                            <div className="form-control-wrap">
                                <div class="form-control-select">
                                    <select class="form-control form-select mt-0" id="userState" name="userState" data-placeholder="Select a country" data-search="on">
                                        <option value="1">Andaman and Nicobar Islands</option>
                                        <option value="2">Andhra Pradesh</option>
                                        <option value="3">Arunachal Pradesh</option>
                                        <option value="4">Assam</option>
                                        <option value="5">Bihar</option>
                                        <option value="6">Chandigarh</option>
                                        <option value="7">Chhattisgarh</option>
                                        <option value="8">Dadra and Nagar Haveli</option>
                                        <option value="9">Daman and Diu</option>
                                        <option value="10">Delhi</option>
                                        <option value="11">Goa</option>
                                        <option value="12">Gujarat</option>
                                        <option value="13">Haryana</option>
                                        <option value="14">Himachal Pradesh</option>
                                        <option value="15">Jammu and Kashmir</option>
                                        <option value="16">Jharkhand</option>
                                        <option value="17">Karnataka</option>
                                        <option value="18">Kenmore</option>
                                        <option value="19">Kerala</option>
                                        <option value="20">Lakshadweep</option>
                                        <option value="21">Madhya Pradesh</option>
                                        <option value="22">Maharashtra</option>
                                        <option value="23">Manipur</option>
                                        <option value="24">Meghalaya</option>
                                        <option value="25">Mizoram</option>
                                        <option value="26">Nagaland</option>
                                        <option value="27">Narora</option>
                                        <option value="28">Natwar</option>
                                        <option value="29">Odisha</option>
                                        <option value="30">Paschim Medinipur</option>
                                        <option value="31">Pondicherry</option>
                                        <option value="32">Punjab</option>
                                        <option value="33">Rajasthan</option>
                                        <option value="34">Sikkim</option>
                                        <option value="35">Tamil Nadu</option>
                                        <option value="36">Telangana</option>
                                        <option value="37">Tripura</option>
                                        <option value="38">Uttar Pradesh</option>
                                        <option value="39">Uttarakhand</option>
                                        <option value="40">Vaishali</option>
                                        <option value="41">West Bengal</option>
                                    </select>
                                </div>
                            </div>
                        </div>
				    </Col>
                    <Col sm="6" className="">
                        <div className="form-group">
                            <Label htmlFor="userCity" className="form-label">City</Label>
                            <div className="form-control-wrap">
                                <div className="form-control-select">
                                <Input type="select" name="userCity" id="userCity" className="form-control form-select mt-0">
                                </Input>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="6" className="">
                        <div className="form-group">
                            <Label className="form-label" for="zip_code">Zip Code</Label>
                            <Input id="zip_code" name="zip_code" value="" type="text" className="form-control focus" />
                        </div>
                    </Col>
                    <Col sm="6" className="">
                        <div className="form-group">
                            <Label className="form-label" for="work_website">Website URL</Label>
                            <Input id="work_website" value="" type="text" className="form-control focus"/>
                        </div>
                    </Col>
                    <Col className="col-12">
                        <Row className="form-group">
                            <Col md="6" className="mb-4">
                                <div className="form-group">
                                    <Label className="form-label" htmlFor="default-1-01">User Description</Label>
                                    <textarea type="text" className="form-control focus" id="user_description"></textarea>
                                </div>
                            </Col>
                            <Col md="6" className="">
                                <div className="form-group">
                                    <Label className="form-label" htmlFor="default-textarea">About Me</Label>
                                    <div className="form-control-wrap">
                                        <textarea id="about_me" className="form-control no-resize focus"></textarea>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-12">
                        <div className="form-group">
                            <button id="savePersonalInformation" className="btn  btn-primary">Save Personal Information</button>
                        </div>
                    </Col>
                </Row>
            </form>
        </PreviewCard>
    </Block>
  );
}

export default PersonalInformation;
