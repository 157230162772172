import React, { useState, useEffect } from "react";
import { Table, Pagination, PaginationItem, PaginationLink, Card, Input, Label, Spinner } from "reactstrap";
import Content from "../../../layout/content/Content";
import "../../../assets/css/AddUser.css";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { getRequest, postRequest } from "../../../api-service";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  Backdrop,
  CircularProgress,
  ButtonBase,
} from "@mui/material";
import Swal from "sweetalert2";
import { useTheme } from "../../../layout/provider/Theme";

const UserKYCData = () => {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataForPagination, setDataForPagination] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [expandedAddresses, setExpandedAddresses] = useState({});
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  console.log("selecteduser", selectedUser);
  const [open, setOpen] = useState(false); // State for backdrop

  useEffect(() => {
    getUserKYCData();
  }, [itemsPerPage, currentPage, searchKeyword]);

  const getUserKYCData = async () => {
    try {
      setLoading(true);
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        search_keyword: searchKeyword,
      };

      const res = await postRequest(
        `/kyc/get-kyc-data?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`
      );
      setData(res.data.coin);
      setDataForPagination(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user KYC data:", error);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset to first page when changing search keyword
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const toggleAddressVisibility = (userId, addressType) => {
    setExpandedAddresses((prevState) => ({
      ...prevState,
      [userId]: {
        ...prevState[userId],
        [addressType]: !prevState[userId]?.[addressType],
      },
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleUserKYCDetails = (user) => {
    setSelectedUser(user);
    setModal(true);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  let totalPages = Math.ceil(dataForPagination?.count / itemsPerPage);

  // const navigate = useNavigate();

  const handleApprove = (id) => {
    setModal(!modal);

    const payload = {
      kyc_id: id,
      status: 1,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to approve this KYC?",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        // denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setOpen(true);

          // Example API request to approve user's KYC
          postRequest(`/kyc/kyc-approve-reject`, payload).then((res) => {
            setOpen(false);

            Swal.fire({
              title: `${res.message}`,
              //   text: `${res.message}`,
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            getUserKYCData();

            // navigate("/admin-manage-coinexchange/user-kyc-data");
          });
        } else if (result.isDenied) {
          Swal.fire({
            title: "You don't taken any action on this KYC",
            icon: "info",
            showConfirmButton: false,
            timer: 2500,
          });
          // navigate("/admin-manage-coinexchange/user-kyc-data");
        }
      });
    } catch (error) {
      setOpen(false);

      console.error("Error approving user KYC:", error);
    }
  };

  const handleReject = async (id) => {
    setModal(!modal);

    try {
      const { value: rejectionReason } = await Swal.fire({
        title: "Reason Rejection",
        input: "textarea",
        // inputLabel: "Rejection Reason",
        inputPlaceholder: "Enter rejection reason",
        inputAttributes: {
          maxlength: "3000",
          autocapitalize: "off",
          autocorrect: "off",
        },
        showCancelButton: true,
        customClass: {
          popup: "my-swal-popup",
        },
      });

      if (rejectionReason) {
        setOpen(true);
        const payload = {
          kyc_id: id,
          status: 2,
          rejection_reason: rejectionReason,
        };

        const res = await postRequest(`/kyc/kyc-approve-reject`, payload);
        setOpen(false);
        Swal.fire({
          title: `${res.message}`,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: "my-swal-popup",
          },
        });
        getUserKYCData();

        // navigate("/admin-manage-coinexchange/user-kyc-data");
      }
    } catch (error) {
      setOpen(false);

      console.error("Error rejecting user KYC:", error);
    }
  };

  // Example usage:
  // handleReject(userId);

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return {
          backgroundColor: "#c8f7d8",
          padding: "3px",
          color: "#069c38",
          borderRadius: "5px",
          border: "1px solid green",
        };
      case "Pending":
        return {
          backgroundColor: "#f5f3b3",
          padding: "3px",
          color: "#999705",
          borderRadius: "5px",
          border: "1px solid #ede621",
        };
      case "Rejected":
        return {
          backgroundColor: "#f29ba5",
          padding: "3px",
          color: "#bd091e",
          borderRadius: "5px",
          border: "1px solid red",
        };
      default:
        return {
          backgroundColor: "transparent",
        };
    }
  };
  return (
    <div>
      <React.Fragment>
        <Head title="User KYC Data" />
        <Content page="component">
          <Block size="lg" className="package-list-table">
            <BlockHead className={`d-flex`}>
              <BlockHeadContent>
                <BlockTitle tag="h3">User KYC Data</BlockTitle>
              </BlockHeadContent>
            </BlockHead>

            <Card className="card-preview" style={{ padding: "15px" }}>
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <div style={{ width: "20%", margin: "10px 0" }}>
                  <Input
                    type="search"
                    placeholder="Search by name"
                    value={searchKeyword}
                    onChange={handleSearchInputChange}
                  />
                </div>

                <div className="d-flex">
                  <Label className="me-2">Show</Label>
                  <Input
                    type="select"
                    name="select"
                    id="itemsPerPageSelect"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Input>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <Table
                  striped
                  className="all-table"
                  style={{ minWidth: "fit-content", textAlign: "center", border: "0.5px solid #d5d7db" }}
                >
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>DOB</th>
                      {/* <th>Address 1</th>
                      <th>Address 2</th> */}
                      <th>Phone</th>
                      <th>Email</th>
                      {/* <th>Zipcode</th> */}
                      <th>Country</th>
                      <th>State</th>

                      <th>City</th>
                      <th>Status</th>
                      <th>Action</th>

                      {/* <th>Document Front Photo</th>
                      <th>Document Back Photo</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading
                      ? Array.from({ length: itemsPerPage }).map((_, index) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            {Array.from({ length: 10 }).map((_, cellIndex) => (
                              <td key={cellIndex}>
                                <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={100} />
                              </td>
                            ))}
                          </tr>
                        ))
                      : data?.map((user, index) => {
                          const isAddress1Expanded = expandedAddresses[user.id]?.address1;
                          const isAddress2Expanded = expandedAddresses[user.id]?.address2;
                          return (
                            <tr style={{ cursor: "pointer" }} key={user.id} onClick={() => handleUserKYCDetails(user)}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td style={{ minWidth: "170px" }}>{user.first_name + " " + user.last_name}</td>
                              <td style={{ minWidth: "150px" }}>{user.dob}</td>
                              {/* <td style={{ minWidth: "238px" }}>
                                {isAddress1Expanded ? user?.address_1 : `${user.address_1?.substring(0, 20)}`}
                                {user.address_1?.length > 20 && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleAddressVisibility(user.id, "address1");
                                    }}
                                    className="btn btn-link p-0"
                                  >
                                    {isAddress1Expanded ? "Read Less" : "...Read More"}
                                  </button>
                                )}
                              </td>
                              <td style={{ minWidth: "238px" }}>
                                {isAddress2Expanded ? user?.address_2 : `${user?.address_2?.substring(0, 20)}`}
                                {user.address_2?.length > 20 && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleAddressVisibility(user.id, "address2");
                                    }}
                                    className="btn btn-link p-0"
                                  >
                                    {isAddress2Expanded ? "Read Less" : "...Read More"}
                                  </button>
                                )}
                              </td> */}
                              <td>{user.phone_no}</td>
                              <td style={{ minWidth: "200px" }}>{user.email}</td>
                              {/* <td>{user.zip_code}</td> */}
                              <td>{user.country_name}</td>
                              <td>{user.state_name}</td>

                              <td>{user.city}</td>
                              <td>
                                <span style={getStatusColor(user?.status)}>{user.status}</span>
                              </td>
                              <td>
                                <Button size="small">
                                  <Icon name={"eye"} />
                                </Button>
                              </td>

                              {/* <td>
                                <img
                                  src={user.doc_front}
                                  alt="Document Front"
                                  style={{ maxWidth: "50px", maxHeight: "50px" }}
                                />
                              </td>
                              <td>
                                <img
                                  src={user.doc_back}
                                  alt="Document Back"
                                  style={{ maxWidth: "50px", maxHeight: "50px" }}
                                />
                              </td> */}
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
              {/* Pagination */}
              <div className="d-flex justify-content-between mt-2">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink first onClick={() => paginate(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                  </PaginationItem>
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <PaginationItem key={index} active={index + 1 === currentPage}>
                      <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink last onClick={() => paginate(totalPages)} />
                  </PaginationItem>
                </Pagination>
              </div>
            </Card>
          </Block>

          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="error" />
          </Backdrop>
        </Content>
      </React.Fragment>

      {/* Modal for displaying user KYC details */}
      <Dialog open={modal} onClose={toggleModal} maxWidth="md" fullWidth sx={{ textAlign: "center" }}>
        <div style={{ textAlign: "end",  backgroundColor: theme.skin==='dark'?"#2f3030":'#fff', height: "10px" }}>
          <Button
            // color="danger"
            onClick={toggleModal}
            style={{
              // position: "absolute",
              borderRadius: "none",
              borderBottomLeftRadius: "5px",
              textAlign: "center",
              height: "30px",
              width: "20px",
              color: "#fff",
              margin: "1%",

              backgroundColor: "#e85347",
            }}
          >
            X
          </Button>
        </div>
        <h3 style={{color: theme.skin==='dark' ? '#fff':'#000', backgroundColor: theme.skin==='dark'?"#2f3030":'#fff'}}>User KYC Details</h3>

        <DialogContent dividers sx={{ backgroundColor: theme.skin==='dark'?"#2f3030":'#fff', color: theme.skin==='dark'?"#fff":'#000'}}>
          {selectedUser && (
            <div>
              <Grid container spacing={2} sx={{ textAlign: "center", margin: "auto" }}>
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <p className="d-flex">
                    <strong>Name:</strong>{" "}
                    {isLoading ? (
                      <Skeleton width="40%" sx={{ ml: 2 }} />
                    ) : (
                      `${selectedUser.first_name} ${selectedUser.last_name}`
                    )}
                  </p>
                  <p className="d-flex">
                    <strong>DOB:</strong> {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.dob}
                  </p>
                  <p className="d-flex">
                    <strong>Phone:</strong>{" "}
                    {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.phone_no}
                  </p>
                  <p className="d-flex">
                    <strong>Email:</strong> {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.email}
                  </p>
                  <div>
                    <strong>Document:</strong>{" "}
                  </div>
                  <div className="d-flex justify-content-around">
                    <div>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width={175} height={125} style={{ marginTop: "15px" }} />
                      ) : (
                        <img
                          src={selectedUser.doc_front}
                          alt="Document Front"
                          style={{
                            borderRadius: "10px",
                            height: "120px",

                            width: "175px",
                            maxWidth: "175px",
                            maxHeight: "125px",
                            marginTop: "15px",
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {isLoading ? (
                        <Skeleton variant="rectangular" width={175} height={125} style={{ marginTop: "15px" }} />
                      ) : (
                        <img
                          src={selectedUser.doc_back}
                          alt="Document Back"
                          style={{
                            borderRadius: "10px",
                            width: "175px",
                            height: "120px",
                            maxWidth: "175px",
                            maxHeight: "125px",
                            marginTop: "15px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "start" }}>
                  <p className="d-flex">
                    <strong>Country:</strong>{" "}
                    {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.country_name}
                  </p>
                  <p className="d-flex">
                    <strong>State:</strong>{" "}
                    {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.state_name}
                  </p>
                  <p className="d-flex">
                    <strong>City: </strong> {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.city}
                  </p>
                  <p className="d-flex">
                    <strong style={{ minWidth: "70px" }}>Address 1: </strong>{" "}
                    {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.address_1}
                  </p>
                  <p className="d-flex">
                    <strong>Zipcode: </strong>{" "}
                    {isLoading ? <Skeleton width="40%" sx={{ ml: 2 }} /> : selectedUser.zip_code}
                  </p>
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: theme.skin==='dark'?"#2f3030":'#fff', color: theme.skin==='dark'?"#fff":'#000'}}>
          <Button
            // disabled={selectedUser?.status === "Approved" || selectedUser?.status === "Rejected" ? true : false}
            size="small"
            color="success"
            variant="contained"
            style={{
              display: selectedUser?.status === "Approved" || selectedUser?.status === "Rejected" ? "none" : "",
            }}
            onClick={() => handleApprove(selectedUser.id)}
          >
            Approve
          </Button>
          <Button
            // disabled={selectedUser?.status === "Approved" || selectedUser?.status === "Rejected" ? true : false}
            size="small"
            color="error"
            variant="outlined"
            style={{
              display: selectedUser?.status === "Approved" || selectedUser?.status === "Rejected" ? "none" : "",
            }}
            onClick={() => handleReject(selectedUser.id)}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserKYCData;
