import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import { Link, useParams } from "react-router-dom";
import { postRequest, getRequest } from "../../../api-service";
import { Grid, Button } from "@mui/material";
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import "../../../../src/main.css";
// ====================================================================================================================================

import classNames from "classnames";
// import { ArrowDownToLine } from "lucide-react";
import {
  Col,
  Label,
  Row,
  TabContent,
  TabPane,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Card,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { useTheme } from "../../../layout/provider/Theme";

const CoinDetails = () => {
  const { id } = useParams();
  const theme = useTheme();

  const [coin, setCoin] = useState({});
  const [investors, setInvestors] = useState([]);
  const [showInvestorsTable, setShowInvestorsTable] = useState(false);
  const [showTextReport, setShowTextReport] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCoinData();
  }, [id]);

  const coinId = localStorage.getItem("IDForCoinDetails");

  const getCoinData = async () => {
    const payload = {
      coin_id: id,
    };
    try {
      const response = await postRequest(`/coin/get-admin-coin`, payload);
      const data = response.data.coin[0];
      // console.log("data", data);
      setCoin(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  };

  useEffect(() => {
    setShowInvestorsTable(true);
    fetchInvestorsData();
  }, []);
  const fetchInvestorsData = async () => {
    try {
      const response = await postRequest(`/coin/get-coin-investment`, { coin_id: id });
      const investorsData = response.data.investors;
      setInvestors(investorsData);
      setShowInvestorsTable(true);
      setShowTextReport(false); // Ensure text report is hidden when showing investors
    } catch (error) {
      console.error("Error fetching investors data:", error);
    }
  };

  const handleSeeInvestorsClick = () => {
    setShowInvestorsTable(true);
    setShowTextReport(false);

    fetchInvestorsData();
  };

  const handleSeeReportsClick = () => {
    setShowTextReport(true);
    setShowInvestorsTable(false); // Ensure investors table is hidden when showing text report
  };

  // ==================================================================================================================

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [doc, setDoc] = useState("");
  const [open, setOpen] = useState(false);
  const [docPresent, setDocPresent] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("");

  // console.log("doc", doc);

  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? "" : id);
  };

  const getDocumentdata = async () => {
    setOpen(true);
    setLoading1(true);
    setDocPresent(false);

    try {
      const payload = {
        coin_id: id,
        category_id: selectedCategoryId,
        sub_category_id: selectedSubCategoryId,
      };
      const res = await postRequest("/reports/get-pdf-reports", payload);
      if (res.data) {
        setDoc(res.data.count[0].report_file);
        setDocPresent(false);
      } else {
        setDoc("");
        setDocPresent(true);
      }
      setLoading1(false);
      setOpen(false);
    } catch (error) {
      console.error("Error fetching document data:", error);
      setLoading1(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getRequest("/reports/get-category");
        if (response.status === true) {
          setCategories(response.data.category_data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await getRequest(`/reports/get-sub-category`);
        if (response.status === true) {
          setSubCategories(response.data.category_data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubCategories();
  }, []);

  useEffect(() => {
    if (selectedCategoryId && selectedSubCategoryId) {
      getDocumentdata();
    }
  }, [selectedCategoryId, selectedSubCategoryId]);

  const handleSubCategoryClick = (categoryId, subCategoryId) => {
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(subCategoryId);
  };

  return (
    <>
      <Content page="component">
        <Block size="lg" className="package-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Coin Details</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link to={-1} className="btn btn-outline-light bg-white mx-1 tooltip_toggler" title="Back">
                <Icon className="ni ni-first" />
                <span style={{ marginLeft: "-5%" }}>Back</span>
              </Link>
            </BlockHeadContent>
          </BlockHead>
        </Block>
        <Block>
          <Card className="card-coin-detail" sx={{ mt: 5, width: "100%" }}>
            <div className="d-flex mt-3 justify-content-center">
              <div>
                <img src={coin.coin_logo} alt="" style={{ borderRadius: "50%", height: "80px", width: "80px" }} />
              </div>
              <div style={{ marginLeft: "2%", marginTop: "1.3%" }}>
                <h4>{coin.coin_name}</h4>
                <h6>{coin.coin_symbol}</h6>
              </div>
            </div>

            <hr />

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", ml: 2 }}>
                <Button
                  variant={showInvestorsTable ? "contained" : "outlined"}
                  color="error"
                  size="small"
                  style={{ borderRadius: "3px", height: "fit-content", marginRight: "3px" }}
                  onClick={handleSeeInvestorsClick}
                >
                  <Icon className="ni ni-users" />
                  <span>Investors</span>
                </Button>
                <Button
                  variant={showTextReport ? "contained" : "outlined"}
                  color="error"
                  size="small"
                  style={{ borderRadius: "3px", height: "fit-content", textAlign: "start", marginLeft: "5px" }}
                  onClick={handleSeeReportsClick}
                >
                  <Icon className="ni ni-files" />
                  <span>Reports</span>
                </Button>
              </Grid>
            </Grid>

            <hr />

            {/* Display Investors Table */}
            {showInvestorsTable && (
              <TableContainer sx={{ mt: 3, p: 2 }}>
                <Table
                  sx={{ minWidth: 650, border: "1px solid #c9c8c5", borderRadius: "10px", textAlign: "center" }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>User ID</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Investment Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {investors === undefined ? (
                      <>
                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                          Data Not Available
                        </TableCell>
                      </>
                    ) : (
                      investors?.map((investor) => (
                        <TableRow key={investor.user_id}>
                          <TableCell>{investor.user_id}</TableCell>
                          <TableCell>{investor.username}</TableCell>
                          <TableCell>{investor.email}</TableCell>
                          <TableCell>{investor.investment_amount}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {/* Display Text Report */}
            {showTextReport && (
              <Row className="g-gs p-2">
                <Col md={3}>
                  <Accordion
                    open={openAccordion}
                    toggle={toggleAccordion}
                    style={{ borderBottom: "1px solid #acb0ad" }}
                  >
                    {categories.map((category) => (
                      <AccordionItem key={category.id}>
                        <AccordionHeader targetId={category.id} style={{ borderBottom: "1px solid #acb0ad" }}>
                          {category.category_name}
                        </AccordionHeader>
                        <AccordionBody accordionId={category.id} style={{ textAlign: "center" }}>
                          <ul>
                            {subCategories.map((subCategory) => (
                              <li
                                key={subCategory.id}
                                style={{
                                  cursor: "pointer",
                                  textTransform: "capitalize",
                                  borderBottom: "1px solid #edf0ee",
                                }}
                                onClick={() => handleSubCategoryClick(category.id, subCategory.id)}
                              >
                                {subCategory.sub_category_name}
                              </li>
                            ))}
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </Col>
                <Col md={9}>
                  <div style={{ textAlign: "center" }}>
                    {/* {docPresent && (
                      <div className="text-center mt-5">
                        <h3>No report data found</h3>
                        <p>
                          There is no document available for this category and sub-category. Please try selecting
                          different ones.
                        </p>
                      </div>
                    )} */}
                    {/* {loading1 ? (
                        <CircularProgress color="error" />
                      ) : doc ? (
                        <iframe
                          src={doc}
                          width="100%"
                          height="500px"
                          title="Coin Report PDF"
                          style={{ border: "none" }}
                        ></iframe>
                      ) : (
                        <p>Please select a category and sub-category to view the document.</p>
                      )} */}

                    {loading1 ? (
                      <CircularProgress color="error" />
                    ) : doc ? (
                      <iframe
                        src={doc}
                        width="100%"
                        height="500px"
                        title="Coin Report PDF"
                        style={{ border: "none" }}
                        // onLoad={() => console.log("PDF loaded successfully")}
                        // onError={(e) => console.error("Error loading PDF:", e)}
                      ></iframe>
                    ) : (
                      <div className="text-center mt-5">
                        <h3>No report data found</h3>
                        <p>
                          There is no document available for this category and sub-category. Please try selecting
                          different ones.
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Card>
        </Block>
      </Content>
    </>
  );
};

export default CoinDetails;
