import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import React  from "react";
import { UserAvatar, Icon } from "../../../components/Component";
import Userdata from "../../user/Userdata";
import NewsAction from "../../user/NewsAction";
import PackagesAction from "../../user/PackagesAction";
import UserViewDetails from "../../user/UserViewDetails";
import UserRole from "../../user/UserRole";
import { findUpper } from "../../../utils/Utils";


export const dataTableColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Age",
    selector: (row) => row.age,
    sortable: true,
    hide: 370,
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
    sortable: true,
    hide: "sm",
  },
  {
    name: "Company",
    selector: (row) => row.company,
    sortable: true,
    hide: "sm",
  },
  {
    name: "Start Date",
    selector: (row) => row.startDate,
    sortable: true,
    hide: "md",
  },
  {
    name: "Salary",
    selector: (row) => row.salary,
    sortable: true,
    hide: "md",
  },
];

export const recentUserDataTableColumns = [
  {
    name: "User",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Silo tokens",
    selector: (row) => row.balance,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount">
        
      </span>
    ),
    sortable: true,
    hide: 480,
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
    sortable: true,
    cell: (row) => <span></span>,
    hide: "md",
  },
  {
    name: "User Role",
    selector: (row) => row.user_role,
    sortable: false,
    cell: (row) => 
          <div>
            
          </div>,
    hide: "md",
  },
  {
    name: "Verified",
    selector: (row) => row.verified,
    sortable: false,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <ul className="list-status d-flex">
        <li>
         
        </li>
        <li>
          
        </li>
      </ul>
    ),
  },
  {
    name: "Last Login",
    selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span></span>,
    hide: "lg",
  },
  {
    name: "Created At",
    selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span></span>,
    hide: "lg",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    hide: "sm",
    cell: (row) => (
      <span>
       
      </span>
    ),
  },
  {
    name: "Is_deleted",
    selector: (row) => row.is_deleted,
    sortable: false,
    hide: "sm",
    cell: (row) => (
      <span>
        
      </span>
    ),
  },
  {
    name: <Icon name="account-setting-alt" />,
    // selector: (row) => row.is_deleted,
    sortable: false,
    hide: "md",
    cell: (row) => (
      
      <div className="ms-4">
       
      </div>
  ),
},

];

export const DataTableData = [
  {
    id: 0,
    name: "Francine Kirby",
    age: 24,
    gender: "female",
    company: "BUZZWORKS",
    startDate: "2017-02-17",
    salary: "$2,570.39",
  },
  {
    id: 1,
    name: "Reva Best",
    age: 40,
    gender: "female",
    company: "MARQET",
    startDate: "2021-10-14",
    salary: "$1,488.76",
  },
  {
    id: 2,
    name: "Alexis Flores",
    age: 21,
    gender: "female",
    company: "OBONES",
    startDate: "2020-04-28",
    salary: "$1,336.93",
  },
  {
    id: 3,
    name: "Nixon Sullivan",
    age: 30,
    gender: "male",
    company: "SLUMBERIA",
    startDate: "2016-10-08",
    salary: "$2,156.70",
  },
  {
    id: 4,
    name: "Foreman Wooten",
    age: 20,
    gender: "male",
    company: "ESCENTA",
    startDate: "2018-07-12",
    salary: "$3,057.42",
  },
  {
    id: 5,
    name: "Franco Davis",
    age: 28,
    gender: "male",
    company: "ZILLACON",
    startDate: "2015-10-08",
    salary: "$2,730.88",
  },
  {
    id: 6,
    name: "Bullock Kline",
    age: 32,
    gender: "male",
    company: "SAVVY",
    startDate: "2017-07-03",
    salary: "$2,986.05",
  },
  {
    id: 7,
    name: "Baird Coffey",
    age: 36,
    gender: "male",
    company: "BLEENDOT",
    startDate: "2014-01-27",
    salary: "$2,755.80",
  },
  {
    id: 8,
    name: "Eula Walters",
    age: 40,
    gender: "female",
    company: "UXMOX",
    startDate: "2020-09-19",
    salary: "$3,302.75",
  },
  {
    id: 9,
    name: "Gaines Moss",
    age: 26,
    gender: "male",
    company: "INCUBUS",
    startDate: "2017-10-13",
    salary: "$3,856.24",
  },
  {
    id: 10,
    name: "Sargent Winters",
    age: 28,
    gender: "male",
    company: "AUSTEX",
    startDate: "2020-12-26",
    salary: "$3,668.64",
  },
  {
    id: 11,
    name: "Sybil Patton",
    age: 35,
    gender: "female",
    company: "ZILIDIUM",
    startDate: "2020-06-19",
    salary: "$1,987.14",
  },
  {
    id: 12,
    name: "Henderson Elliott",
    age: 39,
    gender: "male",
    company: "ZOARERE",
    startDate: "2016-08-16",
    salary: "$1,795.31",
  },
  {
    id: 13,
    name: "Combs Irwin",
    age: 33,
    gender: "male",
    company: "COLAIRE",
    startDate: "2017-07-19",
    salary: "$2,396.73",
  },
  {
    id: 14,
    name: "Fleming Buchanan",
    age: 33,
    gender: "male",
    company: "WEBIOTIC",
    startDate: "2021-09-12",
    salary: "$3,406.96",
  },
  {
    id: 15,
    name: "Mcbride Dixon",
    age: 25,
    gender: "male",
    company: "ZBOO",
    startDate: "2017-12-08",
    salary: "$1,065.32",
  },
  {
    id: 16,
    name: "Nettie Greer",
    age: 32,
    gender: "female",
    company: "QUONK",
    startDate: "2014-03-15",
    salary: "$1,558.83",
  },
  {
    id: 17,
    name: "Anita Saunders",
    age: 39,
    gender: "female",
    company: "IDEALIS",
    startDate: "2015-07-31",
    salary: "$1,848.84",
  },
  {
    id: 18,
    name: "Darcy Mcclain",
    age: 24,
    gender: "female",
    company: "DIGIGEN",
    startDate: "2020-02-19",
    salary: "$3,382.78",
  },
  {
    id: 19,
    name: "Jodi Knowles",
    age: 32,
    gender: "female",
    company: "KONGENE",
    startDate: "2014-03-09",
    salary: "$1,668.05",
  },
  {
    id: 20,
    name: "Cathleen Schroeder",
    age: 21,
    gender: "female",
    company: "TROPOLIS",
    startDate: "2014-09-28",
    salary: "$2,730.21",
  },
  {
    id: 21,
    name: "Lea Fitzgerald",
    age: 24,
    gender: "female",
    company: "AVENETRO",
    startDate: "2015-08-17",
    salary: "$2,547.85",
  },
  {
    id: 22,
    name: "Pitts Graham",
    age: 20,
    gender: "male",
    company: "MALATHION",
    startDate: "2020-05-08",
    salary: "$3,538.05",
  },
  {
    id: 23,
    name: "Lane Glass",
    age: 22,
    gender: "male",
    company: "NETROPIC",
    startDate: "2020-01-26",
    salary: "$1,141.42",
  },
  {
    id: 24,
    name: "Tisha Cleveland",
    age: 33,
    gender: "female",
    company: "YURTURE",
    startDate: "2020-01-11",
    salary: "$2,877.89",
  },
  {
    id: 25,
    name: "Ortiz Nguyen",
    age: 34,
    gender: "male",
    company: "TRIBALOG",
    startDate: "2019-07-09",
    salary: "$3,156.79",
  },
  {
    id: 26,
    name: "Katrina Alvarado",
    age: 33,
    gender: "female",
    company: "PYRAMIA",
    startDate: "2016-07-04",
    salary: "$2,273.02",
  },
  {
    id: 27,
    name: "Craig Chang",
    age: 30,
    gender: "male",
    company: "COMVEYER",
    startDate: "2019-09-08",
    salary: "$3,028.17",
  },
  {
    id: 28,
    name: "Joann Short",
    age: 30,
    gender: "female",
    company: "PRISMATIC",
    startDate: "2017-08-17",
    salary: "$2,041.14",
  },
  {
    id: 29,
    name: "Vargas Rivers",
    age: 23,
    gender: "male",
    company: "ELPRO",
    startDate: "2014-04-25",
    salary: "$1,906.04",
  },
  {
    id: 30,
    name: "Snow Hampton",
    age: 37,
    gender: "male",
    company: "SNORUS",
    startDate: "2014-11-30",
    salary: "$1,419.30",
  },
  {
    id: 31,
    name: "Ellison Pennington",
    age: 32,
    gender: "male",
    company: "APEX",
    startDate: "2020-02-06",
    salary: "$3,486.62",
  },
  {
    id: 32,
    name: "Kate Donaldson",
    age: 28,
    gender: "female",
    company: "TALENDULA",
    startDate: "2021-07-05",
    salary: "$3,025.63",
  },
  {
    id: 33,
    name: "Bridges Franco",
    age: 20,
    gender: "male",
    company: "FURNAFIX",
    startDate: "2021-09-21",
    salary: "$1,371.72",
  },
  {
    id: 34,
    name: "Montgomery Moreno",
    age: 24,
    gender: "male",
    company: "ZIORE",
    startDate: "2018-08-04",
    salary: "$1,016.90",
  },
  {
    id: 35,
    name: "Meyers Barnett",
    age: 25,
    gender: "male",
    company: "OCEANICA",
    startDate: "2017-03-04",
    salary: "$3,804.05",
  },
  {
    id: 36,
    name: "Gertrude Glenn",
    age: 29,
    gender: "female",
    company: "FORTEAN",
    startDate: "2018-04-19",
    salary: "$3,883.97",
  },
  {
    id: 37,
    name: "Wise Fitzpatrick",
    age: 34,
    gender: "male",
    company: "RODEOLOGY",
    startDate: "2017-11-08",
    salary: "$1,400.23",
  },
  {
    id: 38,
    name: "Joseph Leonard",
    age: 30,
    gender: "male",
    company: "QIMONK",
    startDate: "2014-12-01",
    salary: "$2,689.09",
  },
  {
    id: 39,
    name: "Booker Chambers",
    age: 24,
    gender: "male",
    company: "SKYPLEX",
    startDate: "2014-07-29",
    salary: "$3,949.05",
  },
  {
    id: 40,
    name: "Corrine Kerr",
    age: 35,
    gender: "female",
    company: "FIBEROX",
    startDate: "2019-06-07",
    salary: "$2,245.15",
  },
  {
    id: 41,
    name: "Williamson Daniel",
    age: 26,
    gender: "male",
    company: "GREEKER",
    startDate: "2020-09-15",
    salary: "$3,814.20",
  },
  {
    id: 42,
    name: "Anthony Oneill",
    age: 36,
    gender: "male",
    company: "MIXERS",
    startDate: "2020-07-22",
    salary: "$1,129.99",
  },
  {
    id: 43,
    name: "Marquita Hubbard",
    age: 25,
    gender: "female",
    company: "VELOS",
    startDate: "2015-11-19",
    salary: "$2,227.39",
  },
  {
    id: 44,
    name: "Dena Clements",
    age: 34,
    gender: "female",
    company: "ORBEAN",
    startDate: "2020-08-31",
    salary: "$2,689.21",
  },
  {
    id: 45,
    name: "Tia Curry",
    age: 37,
    gender: "female",
    company: "MUSAPHICS",
    startDate: "2019-04-02",
    salary: "$3,784.72",
  },
  {
    id: 46,
    name: "Rios House",
    age: 30,
    gender: "male",
    company: "IMPERIUM",
    startDate: "2015-08-23",
    salary: "$1,519.37",
  },
  {
    id: 47,
    name: "Whitfield Mcleod",
    age: 37,
    gender: "male",
    company: "SCHOOLIO",
    startDate: "2015-03-17",
    salary: "$2,365.21",
  },
  {
    id: 48,
    name: "Conrad Holt",
    age: 38,
    gender: "male",
    company: "MENBRAIN",
    startDate: "2020-02-01",
    salary: "$2,289.04",
  },
  {
    id: 49,
    name: "Mclaughlin Fletcher",
    age: 34,
    gender: "male",
    company: "SOLAREN",
    startDate: "2018-09-05",
    salary: "$1,115.62",
  },
];
export const dataTableColumns3 = [
  {
    name: "Service Name",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Plan Name",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Start Date",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "End Date",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Created Date",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Updated Date",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },

];
export const notificationDataTableColumns = [
  {
    name: "From User",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Title",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Link",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Notification Type",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Notification Time",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },

];
export const miningDataTableColumns = [
  {
    name: "Sr.No",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Txn Id",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "From To",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Tokens",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Date Time",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Miner#",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Mining Time",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Mining Fee",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },

];
export const transactionDataTableColumns = [
  {
    name: "Txn Id",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "From To",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Tokens",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Date Time",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
];
export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "John Doe",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    // checked: false,
    email: "info@silocloud.com",
    balance: "35,040.34",
    phone: "818474958",
    user_role : "Normal",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 Feb 2020",
    status: "Active",
    is_deleted : "No",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    image: User3,
    name: "Ashley Lawson",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@silocloud.com",
    balance: "580.00",
    // checked: false,
    phone: "1243941787",
    user_role : "Normal",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07 Feb 2020",
    status: "Pending",
    is_deleted : "No",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 3,
    avatarBg: "info",
    name: "Joe Larson",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "larson@example.com",
    balance: "32,000.34",
    // checked: false,
    phone: "1686032320",
    user_role : "Normal",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "04 Feb 2020",
    status: "Active",
    is_deleted : "No",
    country: "England",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 4,
    avatarBg: "danger",
    name: "Jane Montgomery",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "jane84@example.com",
    balance: "0.00",
    // checked: false,
    phone: "4392715360",
    user_role : "Normal",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "01 Feb 2020",
    status: "Suspend",
    is_deleted : "No",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 5,
    avatarBg: "purple",
    name: "Frances Burns",
    dob: "30 May, 2000",
    role: "Manager",
    image: User,
    email: "frances@example.com",
    balance: "42.50",
    // checked: false,
    phone: "6391303150",
    user_role : "Normal",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "31 Jan 2020",
    status: "Active",
    is_deleted : "No",
    country: "Bangladesh",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
];
export const dataTableColumns2 = [
  {
    name: "User",
    selector: (row) => row.name,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        <UserAvatar theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
        <div className="user-info">
          <span className="tb-lead">
            {row.name}{" "}
            <span
              className={`dot dot-${
                row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
              } d-md-none ms-1`}
            ></span>
          </span>
          <span>{row.email}</span>
        </div>
      </div>
    ),
    sortable: true,
  },
  {
    name: "Silo tokens",
    selector: (row) => row.balance,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount">
        {row.balance} <span className="currency">USD</span>
      </span>
    ),
    sortable: true,
    hide: 480,
  },
  {
    name: "Phone",
    selector: (row) => row.phone,
    sortable: true,
    cell: (row) => <span>{row.phone}</span>,
    hide: "md",
  },
  {
    name: "User Role",
    selector: (row) => row.user_role,
    sortable: false,
    cell: (row) => 
          <div>
            <UserRole row={row} /> 
          </div>,
    hide: "md",
  },
  {
    name: "Verified",
    selector: (row) => row.verified,
    sortable: false,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <ul className="list-status d-flex">
        <li>
          <Icon
            className={`text-${
              row.emailStatus === "success" ? "success" : row.emailStatus === "pending" ? "warning" : "secondary"
            }`}
            name={`${
              row.emailStatus === "success"
                ? "check-circle"
                : row.emailStatus === "alert"
                ? "alert-circle"
                : "alert-circle"
            }`}
          ></Icon>{" "}
          <span><Icon
            name={`mail`}
          ></Icon></span>
        </li>
        <li>
          <Icon
            className={`text-${
              row.kycStatus === "success"
                ? "success"
                : row.kycStatus === "pending"
                ? "warning"
                : row.kycStatus === "warning"
                ? "warning"
                : "secondary"
            }`}
            name={`${
              row.kycStatus === "success" ? "check-circle" : row.kycStatus === "pending" ? "alert-circle" : "alert-circle"
            }`}
          ></Icon>{" "}
          <span><Icon
            name={`mobile`}
          ></Icon>{" "}</span>
        </li>
      </ul>
    ),
  },
  {
    name: "Last Login",
    selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span>{row.lastLogin}</span>,
    hide: "lg",
  },
  {
    name: "Created At",
    selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span>{row.lastLogin}</span>,
    hide: "lg",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    hide: "sm",
    cell: (row) => (
      <span
        className={`tb-status ms-1 text-${
          row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
        }`}
      >
        {row.status}
      </span>
    ),
  },
  {
    name: "Is_deleted",
    selector: (row) => row.is_deleted,
    sortable: false,
    hide: "sm",
    cell: (row) => (
      <span
        className={`tb-status ms-1 text-${
          row.is_deleted === "Yes" ? "No" : row.is_deleted === "No" ? "Yes" : "Yes"
        }`}
      >
        {row.is_deleted}
      </span>
    ),
  },
  {
    name: <Icon name="account-setting-alt" />,
    // selector: (row) => row.is_deleted,
    sortable: false,
    hide: "md",
    cell: (row) => (
      
      <div className="ms-4">
       <Userdata row={row} />
      </div>
  ),
},

];
export const MaildataTableColumns = [
  {
    name: "User",
    selector: (row) => row.user,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        {row.user}
      </div>
    ),
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount">
        {row.email}
      </span>
    ),
    sortable: false,
    hide: 480,
  },
  {
    name: "Message",
    selector: (row) => row.message,
    sortable: false,
    marginLeft:"22px",
    cell: (row) => <span className="ms-4">{row.message}</span>,
    hide: "md",
  },
  {
    name: "Subject",
    selector: (row) => row.subject,
    sortable: false,
    cell: (row) => 
          <div className="ms-4">
           {row.subject}
          </div>,
    hide: "md",
  },
  {
    name: "Time",
    selector: (row) => row.time,
    sortable: false,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <div>{row.time}</div>
    ),
  },
  {
    name: "Action",
    // selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span className="ms-2"><a href="https://webmail.silocloud.com/" className="tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="Replay">
    <button className="btn btn-primary mx-1"><em className="icon ni ni-reply-fill"></em></button></a></span>,
    hide: "lg",
  },
];
export const userLogDataTableColumns = [
  {
    name: "Id",
    // selector: (row) => row.user,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-3">
        
      </div>
    ),
    sortable: true,
  },
  {
    name: "User",
    // selector: (row) => row.email,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount">
        
      </span>
    ),
    sortable: true,
    hide: 480,
  },
  {
    name: "Field",
    // selector: (row) => row.message,
    sortable: true,
    marginLeft:"22px",
    cell: (row) => <span className="ms-4"></span>,
    hide: "md",
  },
  {
    name: "Old Value",
    // selector: (row) => row.subject,
    sortable: true,
    cell: (row) => 
          <div className="ms-4">
           
          </div>,
    hide: "md",
  },
  {
    name: "New Value",
    // selector: (row) => row.time,
    sortable: true,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <div></div>
    ),
  },
  {
    name: "Updated By",
    // selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span></span>,
    hide: "lg",
  },
  {
    name: "Updated At",
    // selector: (row) => row.lastLogin,
    sortable: true,
    cell: (row) => <span></span>,
    hide: "lg",
  },
];
export const AdminNewsdataTableColumns = [
  {
    name: "Sr.No",
    selector: (row) => row.id,
    compact: true,
    grow: 2,
    style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 ms-5">
        {row.id}
      </div>
    ),
    sortable: false,
  },
  {
    name: "Title",
    selector: (row) => row.title,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount news">
         {row.title}
      </span>
    ),
    sortable: true,
    hide: 480,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    sortable: true,
    marginLeft:"22px",
    cell: (row) => <span className="ms-4 news"> {row.description}</span>,
    hide: "md",
  },
  {
    name: "Image",
    selector: (row) => row.image,
    sortable: false,
    cell: (row) => 
      <div className="ms-4 news">
        {row.image && <img src={row.image} alt="News" style={{ width: "75px", height: "auto" }} />}
      </div>,
    hide: "md",
  },
  {
    name: "User",
    selector: (row) => row.user,
    sortable: true,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <div className="news">{row.user}</div>
    ),
  },
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true,
    cell: (row) => <span className="news">{row.type}</span>,
    hide: "lg",
  },
  {
    name: "Created At",
    selector: (row) => row.created_at,
    sortable: false,
    cell: (row) => <span>{row.created_at}</span>,
    hide: "lg",
  },
  {
    name: "Action",
    // selector: (row) => row.action,
    sortable: false,
    cell: (row) => <span> <NewsAction row={row} /></span>,
    hide: "lg",
  },
];
export const PackagesDataTableColumns = [
  {
    name: "Sr.No",
    selector: (row) => row.id,
    compact: true,
    // grow: 2,
    // style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 m-auto">
        {row.id}
      </div>
    ),
    sortable: false,
  },
  {
    name: "Package Name",
    selector: (row) => row.package,
    minWidth: "140px",
    cell: (row) => (
      <span className="tb-amount news ms-5">
         {row.package}
      </span>
    ),
    sortable: true,
    hide: "md",
  },
  {
    name: "Thumbnail",
    selector: (row) => row.images,
    sortable: false,
    cell: (row) => 
      <div className="ms-5 news">
        {row.images && <img src={row.images} alt="News" style={{ width: "75px", height: "auto" }} />}
      </div>,
    hide: "md",
  },
  {
    name: "Services Name",
    selector: (row) => row.service_name,
    sortable: true,
    marginLeft:"22px",
    cell: (row) => <span className="ms-4 news">{row.service_name} </span>,
    hide: "md",
  },
  {
    name: "Subscription Days",
    selector: (row) => row.subscription_days,
    sortable: true,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <div className="news m-auto">{row.subscription_days}</div>
    ),
  },
  {
    name: "Price",
    selector: (row) => row.price,
    sortable: true,
    cell: (row) => <span className="news m-auto">{row.price}</span>,
    hide: "md",
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: false,
    cell: (row) => <span className="text-success m-auto">{row.status}</span>,
    hide: "md",
  },
  {
    name: "Action",
    // selector: (row) => row.action,
    sortable: false,
    cell: (row) => <span className="ms-4"> <PackagesAction row={row} /></span>,
    hide: "md",
  },
];
export const ServicesDataTableColumns = [
  {
    name: "Sr.No",
    selector: (row) => row.id,
    compact: true,
    // grow: 2,
    // style: { paddingRight: "20px"},
    cell: (row) => (
      <div className="user-card mt-2 mb-2 m-auto">
        {row.id}
      </div>
    ),
    sortable: false,
  },
  {
    name: "Service Logo Image",
    selector: (row) => row.service_logo,
    sortable: false,
    cell: (row) => 
      <div className="ms-5 news">
        {row.service_logo && <img src={row.service_logo} alt="News" style={{ width: "75px", height: "auto" }} />}
      </div>,
    hide: "md",
  },
  {
    name: "Silo5 Logo Image",
    selector: (row) => row.silo5_logo,
    sortable: false,
    cell: (row) => 
      <div className="ms-5 news">
        {row.silo5_logo && <img src={row.silo5_logo} alt="News" style={{ width: "75px", height: "auto" }} />}
      </div>,
    hide: "md",
  },
  {
    name: "Services Name",
    selector: (row) => row.service_name,
    sortable: true,
    marginLeft:"22px",
    cell: (row) => <span className="ms-5 news">{row.service_name} </span>,
    hide: "md",
  },
  {
    name: "Description",
    selector: (row) => row.description,
    sortable: true,
    minWidth: "170px",
    hide: "md",
    cell: (row) => (
      <div className="news ms-5">{row.description}</div>
    ),
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: false,
    cell: (row) => <span className="text-success m-auto">{row.status}</span>,
    hide: "md",
  },
  {
    name: "Action",
    // selector: (row) => row.action,
    sortable: false,
    cell: (row) => <span className="ms-4"> <PackagesAction row={row} /></span>,
    hide: "md",
  },
];
export const userData1 = [
  {
    
  },
];
export const notification = [
  {
    
  },
];
export const mining = [
  {
    
  },
];
export const transaction = [
  {
    
  },
];
export const recentusers = [
  {
    
  },
];
export const usersMail = [
  {
    id: 1,
    user: "Tanveer",
    email: "tambolitanvir@gmail.com",
    message:"Enquiry",
    subject:"Test",
    time:"2023-05-01 23:49:41",
    action:"",
  },
];
export const userlog = [
  {
    
  },
];
export const AdminNews = [
  {
    id:1,
    title :"Test",
    description :"Neque porro quisqua",
    image:"https://demo.silocloud.org/uploads/news/adminNews1708008760608.jpg",
    user: "lael",
    type:"1",
    created_at:"15-Feb-2024 08:52",
    Action:"",
    
  },
];
export const Packages = [
  {
    id:1,
    package : "Enterprise",
    images :"https://demo.silocloud.org/assets/frontend/site_settings/default_package_img.png",
    service_name:"File Manager, Marketplace, Open World, Silo QR Code, Silo Canvas, Silo Chat, Silo Community, Silo Connect, Silo Health, Silo Live Streaming Deck, Silo Music, Silo Radio, Silo Social media, Silo Suite",
    subscription_days :"30",
    price:"59",
    status :"Active",
    
  },
];
export const Services = [
  {
    id:1,
    service_logo : "https://demo.silocloud.org/assets/frontend/site_settings/default_service_logo.png",
    silo5_logo :"https://demo.silocloud.org/assets/frontend/site_settings/default_service_logo.png",
    service_name:"Site Builder",
    description :"Site builder for silo web hosting	",
    status :"Active",
    
  },
];