import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/css/AddUser.css";
import { Sidebar } from '../../components/Component';
import AppWrap from '../../layout/global/AppWrap';


const SiteSettings = () => {
  
  return (
   <>
      <h1>tfgyujo</h1>
    
   </>
  );
};

export default SiteSettings;
