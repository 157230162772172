import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Row, Col, Button } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, PreviewCard, Icon, BlockHeadContent } from "../../../components/Component";
import axios from "axios";
import { getRequest, postRequest, putRequest } from "../../../api-service";
import "../../../assets/css/AddUser.css";
import data from "../../../layout/header/dropdown/notification/NotificationData";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";

const EditCoin = () => {
  const navigate = useNavigate();
  let coinId = localStorage.getItem("coinIdForEdit");
  const [coinData, setCoinData] = useState({
    coin_id: coinId,
    coinName: "",
    coinSymbol: "",
    description: "",
    coinImage: "",
    coinVideo: "",
    price: "",
    marketCap: "",
    supply: "",
    oneHourPrice: "",
    twentyFourHourPrice: "",
    sevenDayPrice: "",
    volume: "",
  });

  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [videoPreview1, setVideoPreview1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [removeVideo, setRemoveVideo] = useState(false);

  const videoInputRef = useRef(null);

  useEffect(() => {
    fetchCoinData();
  }, []);

  const fetchCoinData = async () => {
    const payload = {
      coin_id: coinId,
    };
    try {
      const response = await postRequest(`/coin/get-admin-coin`, payload);
      const data = response.data.coin[0];
      console.log("data", data);
      setCoinData({
        coinName: data.coin_name,
        coinSymbol: data.coin_symbol,
        description: data.description,
        coinImage: data.coin_logo,
        coinVideo: data.video_url,
        price: data.price,
        marketCap: data.market_cap,
        supply: data.circulation_supply,
        oneHourPrice: data.one_h,
        twentyFourHourPrice: data.twenty_four_h,
        sevenDayPrice: data.seven_d,
        volume: data.volume,
      });
      setImagePreview(data.coin_logo);
      setVideoPreview1(data.video_url);
      const imageUrl = data.coin_logo;
      setImageName(imageUrl.split("/").pop());
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setCoinData({ ...coinData, coinImage: selectedImage });
    const reader = new FileReader();
    reader.readAsDataURL(selectedImage);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };

  const handleVideoChange = (e) => {
    const selectedVideo = e.target.files[0];
    setCoinData({ ...coinData, coinVideo: selectedVideo });
    const videoURL = URL.createObjectURL(selectedVideo);
    setVideoPreview(videoURL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("coin_id", coinId);
      formData.append("coin_name", coinData.coinName);
      formData.append("coin_symbol", coinData.coinSymbol.toUpperCase());
      formData.append("description", coinData.description);
      formData.append("price", coinData.price);
      formData.append("market_cap", coinData.marketCap);
      formData.append("circulation_supply", coinData.supply);
      formData.append("one_h", coinData.oneHourPrice);
      formData.append("twenty_four_h", coinData.twentyFourHourPrice);
      formData.append("seven_d", coinData.sevenDayPrice);
      formData.append("volume", coinData.volume);
      formData.append("video_url", coinData.coinVideo);

      if (coinData.coinImage instanceof File) {
        formData.append("coin_logo", coinData.coinImage);
      }

      if (coinData.coinVideo instanceof File) {
        formData.append("video_url", coinData.coinVideo);
      } else if (removeVideo) {
        formData.append("video_url", ""); // Indicate that the video should be removed
      }

      setOpen(true);

      const res = await postRequest(`/coin/update-coin`, formData);

      setOpen(false);

      if (res.status === true) {
        Swal.fire({
          icon: "success",
          title: `${res.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin-manage-coinexchange/coinList");
      } else {
        Swal.fire({
          icon: "error",
          title: `${res.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setOpen(false);
      setError("An error occurred while updating coin data.");
      console.error("Error updating coin data:", error);
    }

    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoinData({ ...coinData, [name]: value });
  };

  const handleVideoCancel = () => {
    if (videoInputRef.current) {
      videoInputRef.current.value = "";
    }
    setCoinData({ ...coinData, coinVideo: "" });
    setVideoPreview("");
    setRemoveVideo(true);
  };

  return (
    <React.Fragment>
      <Head title="Edit Coin" />
      <Content>
        <BlockHead size="lg" className={`d-flex justify-content-between`}>
          <BlockHeadContent>
            <BlockTitle tag="h3">Edit Coin </BlockTitle>
          </BlockHeadContent>
          <Link to={"/admin-manage-coinexchange/coinList"} className="btn btn-outline-light bg-white">
            <Icon className="ni ni-list"></Icon>
            <span style={{ marginLeft: "-5%" }}>Coin List</span>
          </Link>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="coinName" className="form-label">
                      Coin Name
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="coinName"
                        name="coinName"
                        placeholder="Coin Name"
                        type="text"
                        value={coinData.coinName}
                        onChange={handleChange}
                        maxLength={50}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="coinSymbol" className="form-label">
                      Coin Symbol
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="coinSymbol"
                        name="coinSymbol"
                        placeholder="Coin Symbol"
                        type="text"
                        maxLength={5}
                        value={coinData.coinSymbol}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="description" className="form-label">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="description"
                        name="description"
                        placeholder="Description"
                        type="textarea"
                        value={coinData.description}
                        onChange={handleChange}
                        maxLength={3000}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="coinImage" className="form-label">
                      Coin Image
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="coinImage"
                        name="coinImage"
                        placeholder="Coin Image URL"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        // hidden
                      />
                      <label className="custom-file-label" htmlFor="newsImage">
                        {imageName.slice(0, 15)} or{" "}
                        <span onChange={handleImageChange} style={{ color: "#E14954" }}>
                          Choose file
                        </span>
                      </label>
                    </div>
                    <div>
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Coin Preview"
                          height={100}
                          width={100}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      ) : (
                        <img
                          src={coinData.coinImage}
                          alt="Coin Preview"
                          height={100}
                          width={100}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="coinVideo" className="form-label">
                      Coin Video
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="coinVideo"
                        name="coinVideo"
                        placeholder="Coin Video URL"
                        type="file"
                        accept="video/*"
                        onChange={handleVideoChange}
                        ref={videoInputRef}
                      />
                    </div>
                    <div>
                      {videoPreview ? (
                        <video
                          src={videoPreview}
                          controls
                          height={200}
                          width={200}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      ) : (
                        !removeVideo && (
                          <video
                            src={`https://api.silocloud.io/get-file-content/public/${videoPreview1}`}
                            controls
                            height={200}
                            width={200}
                            style={{ borderRadius: "5%", marginTop: "1%" }}
                          />
                        )
                      )}
                    </div>
                    {/* {videoPreview && ( */}
                    <Button color="danger" onClick={handleVideoCancel} style={{ marginTop: "1rem" }}>
                      Cancel Video
                    </Button>
                    {/* )} */}
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="price" className="form-label">
                      Price(USD)
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="price"
                        name="price"
                        placeholder="Price ($)"
                        type="number"
                        value={coinData.price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="marketCap" className="form-label">
                      Market Cap
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="marketCap"
                        name="marketCap"
                        placeholder="Market Cap"
                        type="number"
                        value={coinData.marketCap}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="supply" className="form-label">
                      Supply
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="supply"
                        name="supply"
                        placeholder="Supply"
                        type="number"
                        value={coinData.supply}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="oneHourPrice" className="form-label">
                      One Hour Price
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="oneHourPrice"
                        name="oneHourPrice"
                        placeholder="One Hour Price"
                        type="number"
                        value={coinData.oneHourPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="twentyFourHourPrice" className="form-label">
                      Twenty-Four Hour Price
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="twentyFourHourPrice"
                        name="twentyFourHourPrice"
                        placeholder="Twenty-Four Hour Price"
                        type="number"
                        value={coinData.twentyFourHourPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="sevenDayPrice" className="form-label">
                      Seven Day Price
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="sevenDayPrice"
                        name="sevenDayPrice"
                        placeholder="Seven Day Price"
                        type="number"
                        value={coinData.sevenDayPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="volume" className="form-label">
                      Volume
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="volume"
                        name="volume"
                        placeholder="Volume"
                        type="number"
                        value={coinData.volume}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ margin: "3% 0 0 0", textAlign: "center" }}>
                <Button color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Updating..." : "Update"}
                </Button>
                {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}
              </div>
            </form>
          </PreviewCard>
        </Block>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Content>
    </React.Fragment>
  );
};

export default EditCoin;
