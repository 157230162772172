import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Row, Col, Button, FormGroup, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, PreviewCard, Icon, BlockHeadContent } from "../../../components/Component";
import axios from "axios"; // Import Axios

import "../../../assets/css/AddUser.css";
import { postRequest, getRequest } from "../../../api-service";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import Dropzone from "react-dropzone";

const AddNews = () => {
  const navigate = useNavigate();

  const [coinName, setCoinName] = useState("");
  const [coinList, setCoinList] = useState([]);
  const [newsTitle, setNewsTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [newsImage, setNewsImage] = useState(""); // Assuming you want to add an image field for news as well
  const [imagePreview, setImagePreview] = useState(""); // State for image preview
  const [selectedCoinId, setSelectedCoinId] = useState(Number);
  const [open, setOpen] = useState(false); // State for backdrop

  console.log("selectedCoinId", selectedCoinId);
  useEffect(() => {
    getCoinData();
  }, []);

  const getCoinData = async () => {
    try {
      const res = await postRequest(`/coin/get-admin-coin`);
      console.log("res coin data", res);
      setCoinList(res.data.coin);
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  };

  // Define handleSubmit as asynchronous
  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!selectedCoinId) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Coin Name is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!newsTitle) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "News Title is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!description) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Description is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!authorName) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Author Name is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!newsImage) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "News Image is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // }
    const formData = new FormData();
    formData.append("coin_id", selectedCoinId);
    formData.append("title", newsTitle);
    formData.append("description", description);
    formData.append("author", authorName);
    formData.append("news_img", newsImage); // Append the raw binary data
    setOpen(true); // Show the backdrop when form submission starts

    try {
      const res = postRequest("/coin/add-news", formData).then((res) => {
        if (res.status === true) {
          setOpen(false); // Close the backdrop in case of error

          Swal.fire({
            icon: "success",
            // title: `${res.message}`,
            title: "News data added successfully.",

            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin-manage-coinexchange/newsList");
        } else {
          setOpen(false); // Close the backdrop in case of error

          Swal.fire({
            icon: "error",
            title: `Error while adding data.`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } catch (error) {
      setOpen(false); // Close the backdrop in case of error

      console.error("Error updating coin data:", error);
    }
  };

  const handleImageChange = (e) => {
    // Set the selected image to state and show preview
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setNewsImage(selectedImage);
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
    } else {
      // Handle the case when no file is selected
      setNewsImage(""); // Clear the news image state
      setImagePreview(""); // Clear the image preview state
    }
  };

  const handleDropChange = (acceptedFiles) => {
    const selectedImage = acceptedFiles[0];
    setNewsImage(selectedImage);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleCoinName = (id) => {
    console.log(id);
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content>
        <BlockHead size="lg" className={`d-flex justify-content-between`}>
          <BlockHeadContent>
            <BlockTitle tag="h3">Add News </BlockTitle>
          </BlockHeadContent>
          <Link to={"/admin-manage-coinexchange/newsList"} className="btn btn-outline-light bg-white">
            <Icon className="ni ni-list"></Icon>
            <span style={{ marginLeft: "-5%" }}>News List</span>
          </Link>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label htmlFor="coinName" className="form-label">
                      Coin Name
                    </Label>
                    <Input
                      md="6"
                      required
                      type="select"
                      name="coinName"
                      id="coinName"
                      value={selectedCoinId}
                      onChange={(e) => {
                        const selectedId = e.target.value; // Get the selected ID
                        const selectedCoin = coinList.find((coin) => coin.id === parseInt(selectedId)); // Find the selected coin object
                        if (selectedCoin) {
                          setCoinName(selectedCoin.coin_name); // Set coin name in state
                          setSelectedCoinId(parseInt(selectedId)); // Set coin ID in state
                        }
                      }}
                    >
                      <option value="">Select Coin</option>
                      {coinList.map((coin) => (
                        <option key={coin.id} value={coin.id}>
                          {coin.coin_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="newsTitle" className="form-label">
                      News Title
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="newsTitle"
                        name="newsTitle"
                        placeholder="News Title"
                        type="text"
                        maxLength={60}
                        value={newsTitle}
                        onChange={(e) => setNewsTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="description" className="form-label">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="description"
                        name="description"
                        placeholder="Description"
                        type="textarea"
                        maxLength={3000}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="newsImage" className="form-label">
                      News Image
                    </Label>
                    {/* <div className="form-control-wrap">
                      <Input
                        required
                        id="newsImage"
                        name="newsImage"
                        placeholder="News Image URL"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div> */}

                    <Dropzone onDrop={handleDropChange} accept="image/*">
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          className="dropzone upload-zone dz-clickable"
                          style={{ width: "300px", margin: "auto", padding: "0px" }}
                        >
                          <input type="file" accept="image/*" {...getInputProps()} />
                          {!newsImage && (
                            <div className="dz-message">
                              <span className="dz-message-text">Drag and drop file</span>
                              <span className="dz-message-or">or</span>
                              <Button color="primary">SELECT</Button>
                            </div>
                          )}
                          {imagePreview && (
                            <div className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                              <div className="dz-image">
                                <img src={imagePreview} alt="preview" />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>

                    {/* <div>
                      Show image preview
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="News Preview"
                          height={100}
                          width={100}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      )}
                    </div> */}
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="authorName" className="form-label">
                      Author Name
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="authorName"
                        name="authorName"
                        placeholder="Author Name"
                        type="text"
                        maxLength={50}
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ margin: " 3% 0 0 0", textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </form>
          </PreviewCard>

          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddNews;
