import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../api-service";
import Swal from "sweetalert2";
import { Skeleton, Tooltip } from "@mui/material";

const NewsList = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [selectedNews, setSelectedNews] = useState(null);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dataForPagination, setDataForPagination] = useState([]);

  useEffect(() => {
    getNewsData();
  }, [itemsPerPage, currentPage, searchKeyword]);

  const getNewsData = async () => {
    try {
      setLoading(true);
      const res = await postRequest(
        `/coin/get-admin-news?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`
      );
      setNewsData(res.data.News);
      setDataForPagination(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching news data:", error);
      setLoading(false);
    }
  };

  const handleEdit = (news) => {
    setSelectedNews(news);
    localStorage.setItem("newsIdForEdit", news);
    navigate("/admin-manage-coinexchange/editNews");
  };

  const handleDelete = (newsId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const payload = { news_id: newsId };
          postRequest("/coin/delete-news", payload).then(
            (res) =>
              Swal.fire({
                title: `${res.message}`,
                // text: "Your file has been deleted.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              }),
            getNewsData()
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset to first page when changing search keyword
  };

  // const totalPages = Math.ceil(dataForPagination.totalRecords / itemsPerPage);

  let totalPages = 0;
  {
    searchKeyword
      ? (totalPages = Math.ceil(newsData?.length / itemsPerPage))
      : (totalPages = Math.ceil(dataForPagination?.totalRecords / itemsPerPage));
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month; // Prefix single digit months with a '0'
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day; // Prefix single digit days with a '0'
    }
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <Content page="component">
        <BlockHead className={`d-flex`}>
          <BlockHeadContent>
            <BlockTitle tag="h3">News List</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className={`ms-auto`}>
            <Link
              to={"/admin-manage-coinexchange/addNews"}
              className="btn btn-outline-light bg-white mx-1 tooltip_toggler"
              title="Add News"
              style={{ backgroundColor: "red" }}
            >
              <Icon className="ni ni-plus"></Icon>
              <span style={{ marginLeft: "-5%" }}>Add News</span>
            </Link>
          </BlockHeadContent>
        </BlockHead>

        <Card className="card-preview" style={{ padding: "15px" }}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div style={{ width: "20%", margin: "10px 0" }}>
              <Input
                type="search"
                placeholder="Search by title"
                value={searchKeyword}
                onChange={handleSearchInputChange}
              />
            </div>

            <div className="d-flex">
              <Label className="me-2">Show</Label>
              <Input
                type="select"
                name="select"
                id="itemsPerPageSelect"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                style={{ width: "fit-content", height: "fit-content" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Input>
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <Table
              className="all-table"
              striped
              style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}
            >
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Title</th>
                  <th>Coin Name</th>
                  <th>News Image</th>
                  <th>Author</th>
                  <th>Description</th>
                  <th>Created at</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7">
                      {/* <Spinner style={{ marginLeft: "0%", marginTop: "0%" }} type="grow" color="danger">
                        Loading...
                      </Spinner> */}

                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>

                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between", textAlign: "end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 2, mt: 0.5 }} width={60} />
                        <Skeleton variant="text" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={120} />
                        <Skeleton variant="circular" sx={{ fontSize: "2rem", ml: 4, mr: 2 }} width={60} height={55} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={110} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={180} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1 }} width={100} />
                        <Skeleton variant="text" sx={{ fontSize: "2rem", mr: -12 }} width={80} />
                      </div>
                    </td>
                  </tr>
                ) : (
                  newsData?.map((news, index) => (
                    <tr key={news.id}>
                      <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td style={{ maxWidth: "100px" }}>{news.title}</td>

                      <td style={{ maxWidth: "100px" }}>{news.coin_name}</td>

                      <td>
                        <img src={news.news_img} alt="news image" style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      </td>
                      <td style={{ maxWidth: "100px" }}>{news.author}</td>
                      {/* <td style={{ maxWidth: "200px" }}>{news.description}</td> */}
                      <td
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip title={news.description} placement="left">
                          <span>{news.description}</span>
                        </Tooltip>
                      </td>
                      <td style={{ maxWidth: "200px" }}>{formatDate(news.updated_at)}</td>
                      <td style={{ maxWidth: "100px", textAlign: "center" }}>
                        <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                          <Icon
                            name="edit"
                            style={{ fontSize: "18px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => handleEdit(news.id)}
                          />
                          <Icon
                            name="trash"
                            style={{ fontSize: "18px", marginRight: "5px", cursor: "pointer" }}
                            onClick={() => handleDelete(news.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          {/* Pagination */}
          <div className="d-flex justify-content-between mt-2">
            <Pagination>
              <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink first onClick={() => paginate(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
              </PaginationItem>
              {Array.from({ length: totalPages }).map((_, index) => (
                <PaginationItem key={index} active={index + 1 === currentPage}>
                  <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink next onClick={() => paginate(currentPage + 1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink last onClick={() => paginate(totalPages)} />
              </PaginationItem>
            </Pagination>
          </div>
        </Card>
      </Content>

      {/* Modal for editing */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit News</ModalHeader>
        <ModalBody>
          {selectedNews && (
            <div>
              <p>Edit form for {selectedNews.title}</p>
              {/* Add your form fields for editing */}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={toggleModal}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewsList;
