import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getRequest, postRequest } from "../../../api-service";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import { Avatar, Rating, Skeleton, Tooltip } from "@mui/material";

const ReviewList = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isVisible, setIsVisible] = useState({});

  const [totalReviews, setTotalReviews] = useState();

  useEffect(() => {
    getReviewsData();
  }, [itemsPerPage, currentPage, searchKeyword]);

  const getReviewsData = async () => {
    try {
      const res = await postRequest(
        `/coin/get-admin-reviews?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`
      );
      setReviews(res.data.Reviews);
      setTotalReviews(res.data.totalRecords);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching review data:", error);
    }
  };

  const handleDeleteReview = (reviewId) => {
    const payload = {
      review_id: reviewId,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await getRequest(`/coin/delete-review?review_id=${reviewId}`).then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "Your review has been deleted.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        });
        getReviewsData();
      }
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const handleCheckboxChange = async (reviewId, reviewStatus) => {
    const newStatus = reviewStatus === "1" ? "0" : "1";
    const payload = {
      review_id: reviewId,
      is_approve: newStatus,
    };
    try {
      await postRequest(`/coin/review-approve`, payload).then((res) => {
        Swal.fire({
          title: "Review status updated successfully !!!",
          // text: `${res.message}`,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      });
      setReviews((prevReviews) =>
        prevReviews.map((review) => (review.id === reviewId ? { ...review, is_approve: newStatus } : review))
      );
    } catch (error) {
      console.error("Error updating review status:", error);
    }
  };

  const showRating = (rating) => {
    const totalStars = 5;
    return (
      <div className="rating-container text-center">
        {[...Array(totalStars)].map((star, index) => (
          <span key={index} className={index < rating ? "star filled" : "star"}>
            &#9733;
          </span>
        ))}
      </div>
    );
  };

  const totalPages = Math.ceil(totalReviews / itemsPerPage);

  return (
    <div>
      <Content page="component">
        <Block size="lg" className="package-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Review List</BlockTitle>
            </BlockHeadContent>
          </BlockHead>

          <Card className="card-preview" style={{ padding: "15px" }}>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div style={{ width: "20%", margin: "10px 0" }}>
                <Input type="search" placeholder="Search" value={searchKeyword} onChange={handleSearchInputChange} />
              </div>

              <div className="d-flex">
                <Label className="me-2">Show</Label>
                <Input
                  type="select"
                  name="select"
                  id="itemsPerPageSelect"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Input>
              </div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <Table
                className="all-table"
                striped
                style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}
              >
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>User</th>
                    <th>Coin Name</th>
                    <th>Review Message</th>
                    <th>Rating</th>
                    <th>Active/InActive</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? Array?.from({ length: 9 })?.map((_, index) => (
                        <tr key={index} style={{ textAlign: "center" }}>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={40} />
                          </td>
                          <td style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Skeleton
                              variant="circular"
                              sx={{ fontSize: "2rem", mr: 1, ml: 3 }}
                              width={50}
                              height={50}
                            />
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"50%"} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"100%"} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"100%"} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"100%"} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"100%"} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={"90%"} />
                          </td>
                        </tr>
                      ))
                    : reviews?.map((review, index) => (
                        <tr key={review.id}>
                          <td>{index + 1}</td>
                          <td style={{ padding: "0 20px" }}>
                            <div className="d-flex text-start justify-content-start">
                              {/* <img
                                src={`https://api.silocloud.io/get-file-content/${review.profile_image_path}`}
                                style={{ marginRight: "15px", borderRadius: "50%" }}
                                alt=""
                                height={50}
                                width={50}
                              /> */}

                              <Avatar
                                alt={review?.username.toUpperCase()}
                                style={{ marginRight: "15px", borderRadius: "50%" }}
                                src={`https://api.silocloud.io/get-file-content/${review.profile_image_path}`}
                              />

                             <p style={{marginTop:'5%'}}> {review.username}</p>
                            </div>
                          </td>
                          <td>{review.coin_name}</td>
                          <td
                            style={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* {review.review} */}
                            <Tooltip title={review.review} placement="left">
                              <span>{review.review}</span>
                            </Tooltip>
                          </td>
                          <td className="text-center" style={{ margin: "auto", paddingLeft: "4%" }}>
                            {showRating(review.rating)}
                          </td>
                          <td>
                            <Input
                              style={{
                                marginLeft: "5px",
                                marginTop: "2%",
                                fontSize: "15px",
                                backgroundColor: review.is_approve === "1" ? "red" : "",
                                border: "1px solid red",
                                cursor: "pointer",
                              }}
                              type="checkbox"
                              checked={review.is_approve === "1"}
                              onChange={() => handleCheckboxChange(review.id, review.is_approve)}
                            />
                          </td>
                          <td>
                            <Icon
                              name="trash"
                              onClick={() => handleDeleteReview(review.id)}
                              style={{ fontSize: "18px", marginRight: "5px", cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <Pagination size="sm">
                <PaginationItem>
                  <PaginationLink disabled={currentPage === 1} previous onClick={() => paginate(currentPage - 1)} />
                </PaginationItem>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <PaginationItem key={index} active={currentPage === index + 1}>
                    <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem>
                  <PaginationLink
                    next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </Card>
        </Block>
      </Content>
    </div>
  );
};

export default ReviewList;
