import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import {  Label, Input, Row, Col } from "reactstrap";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";
// hooks
import useShowHidePassword from "../../hooks/use-show-hide-password";

const AddUser = () => {
  const { showPassword, showRePassword, togglePassword} = useShowHidePassword()
  const [previewUrl, setPreviewUrl] = useState("");
  const [countries, setCountries] = useState([]);
  console.log(showPassword, showRePassword)

 
  const previewProfileImage = () => {
    const input = document.getElementById('profile_image');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('uploadProfileImage');

    reader.onloadend = () => {
        img.src = reader.result;
    }

    if (file) {
        reader.readAsDataURL(file);
    }
};

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.silocloud.io/api/v1/public/countries", { method: "GET" });
        if (!response.ok) {
          throw new Error('Failed to fetch countries');
        }
        const data = await response.json();
        //setCountries(data.data.countries);
        
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content >
        <BlockHead size="lg" className={`d-flex`}>
          <BlockHeadContent>
            <BlockTitle tag="h3" className="fw-normal">
              Create User
            </BlockTitle>
           
          </BlockHeadContent>
          <BlockHeadContent className={`ms-auto`}>
          <Link to={"/user-list"} className="btn btn-outline-light bg-white">
			      <Icon className="ni ni-list"></Icon>
		      </Link>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
        <form>
          <PreviewCard> 
            <Col sm="6" className="d-flex justify-content-start">
              <div className="form-group">
                <div className="form-control-wrap mt-5">
                  <div className="form-file mb-5 d-flex justify-content-start">
                    <img
                      src={previewUrl || "https://demo.silocloud.org/assets/cloud/images/launch/img/download.png"}
                      id="uploadProfileImage"
                      alt="Profile"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <Input
                      name="user_image" id="profile_image" accept="image/*" className="profile-input btn btn-primary" type="file" onChange={previewProfileImage} style={{cursor: 'pointer', display: 'block', height: '100px', left: '0', opacity: '0',  position: 'absolute',top: '0', width: '100px'}}
                    />
                  </div>
                  <Label className="text-center form-label mt-5">Choose profile image</Label>
                </div>
              </div>
            </Col>
            <Row className="gy-4">
              <Col sm="6" className="mt-5"> 
                <div className="form-group">
                  <Label htmlFor="username" className="form-label">
                    Username
                  </Label>
                  <div className="form-control-wrap">
                    <Input className="form-control" type="text" id="username" placeholder=""></Input>
                  </div>
                </div>
              </Col>
              <Col sm="6" className="mt-5">
                <div className="form-group">
                  <Label htmlFor="email" className="form-label">
                    Email Address
                  </Label>
                  <div className="form-control-wrap">
                    <Input className="form-control" type="text" id="email" placeholder=""></Input>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label htmlFor="country" className="form-label">
                   Country
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <RSelect type="select" name="select" id="country">
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                      </RSelect>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label htmlFor="phone" className="form-label">
                    Phone Number
                  </Label>
                  <div className="form-control-wrap">
                  <Input className="form-control" type="number" id="phone" placeholder=""></Input>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label htmlFor="password" className="form-label">
                    Password
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-right">
                        <Icon className={`icon ni ${showPassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('password')}></Icon>
                    </div>
                    <Input className="form-control" type={showPassword ? "text" : "password"} id="password" placeholder=""></Input>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label htmlFor="re-password" className="form-label">
                    Re-Enter Password
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-right">
                        <Icon className={`ni ${showRePassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('re-password')}></Icon>
                    </div>
                    <input className="form-control" type={showRePassword ? 'text' : 'password'} id="re-password" placeholder="" />
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group">
                    <button type="submit" className="btn  btn-primary" id="btn-register" name="btn-register">Create User</button>
                </div>
             </Col>
            </Row>
          </PreviewCard>
          </form>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default AddUser;
