import React, { useState, useRef, useEffect } from 'react';
import { Link} from "react-router-dom";
import {
    Icon,
  } from "../../components/Component";
function NewsAction({ row }) {
    const [showDropdownOnClick, setShowDropdownOnClick] = useState(false);
    const [showIconsOnHover, setShowIconsOnHover] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = (event) => {
        event.preventDefault(); 
        setShowDropdownOnClick(!showDropdownOnClick);
        if(showIconsOnHover) {
            setShowIconsOnHover(false); 
        }
    };

    const showIcons = () => {
        if (!showDropdownOnClick) { 
            setShowIconsOnHover(true);
        }
    };

    const hideIcons = () => {
        setShowIconsOnHover(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdownOnClick(false);
            setShowIconsOnHover(false); 
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div  className="ms-5" ref={dropdownRef} onMouseLeave={hideIcons}>
            <Link tag="a" href="#" className="dropdown-toggle btn btn-icon btn-trigger show" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown} onMouseEnter={showIcons} >
                <Icon className="ni ni-more-h"></Icon>
            </Link>
            {showIconsOnHover && !showDropdownOnClick && (
                <div style={{ display: 'flex', position: 'absolute', top: '23%', left: '-8px',right:'0' }}>
                    <Link to={`edit/${row.id}`} className="btn btn-trigger btn-icon tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Edit Details"><Icon className="ni ni-edit-fill"></Icon></Link>
                    <Link to="#" className="btn btn-trigger btn-icon btn-user-delete tooltip_toggler" data-user-id="5261" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Delete User"><Icon className="ni ni-trash-fill"></Icon></Link>
                </div>
            )}
            {showDropdownOnClick && (
                <div
                    className="dropdown-menu dropdown-menu-end show"
                    style={{
                        position: "absolute",
                        inset: "auto 0px 0px auto",
                        margin: "0px",
                        transform: "translate3d(-3px, -38px, 0px)",
                    }}
                    data-popper-placement="bottom-end"
                >
                    <ul className="link-list-opt no-bdr">
                        <li>
                            <Link tag="a" to={`edit/${row.id}`}>
                                <Icon className="ni ni-edit"></Icon>
                                <span>Edit Details</span>
                            </Link>
                        </li>
                        <li>
                            <Link tag="a" to="#" data-user-id="5265" className="btn-user-delete">
                                <Icon className="ni ni-trash"></Icon>
                                <span>Delete News</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default NewsAction;
