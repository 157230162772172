import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import {  Label, Input, Row, Col } from "reactstrap";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";
// hooks
import useShowHidePassword from "../../hooks/use-show-hide-password";


const InputTags = ({ label, placeholder, tags, setTags }) => {
    const [newTag, setNewTag] = useState("");
  
    const handleTagChange = (e) => {
      setNewTag(e.target.value);
    };
  
    const handleTagKeyDown = (e) => {
      if (e.key === "Enter" && newTag.trim() !== "") {
        e.preventDefault();
        setTags([...tags, newTag.trim()]);
        setNewTag("");
      }
    };
  
    const handleTagRemove = (indexToRemove) => {
      setTags(tags.filter((_, index) => index !== indexToRemove));
    };
  
    return (
      <div className="form-group">
        <label className="form-label" htmlFor="tag">
          {label} <small>(Press ENTER to add to list)</small>
        </label>
        <div className="form-control-wrap">
          <div
            className="bootstrap-tagsinput"
            onKeyDown={handleTagKeyDown}
            tabIndex="0"
            style={{
              background: "#28202024",
              border: "2px solid #332f2f80",
              height: "91px",
              overflowY: "scroll",
            }}
          >
            {tags.map((tag, index) => (
              <span key={index} className="badge bg-primary me-1 align-items-center">
                {tag}
                <button
                  type="button"
                  className="btn-close badge-close fs-16px"
                  onClick={() => handleTagRemove(index)}
                  aria-label="Close"
                ></button>
              </span>
            ))}
            <input data-role="tagsinput" type="text" placeholder={placeholder} value={newTag} onChange={handleTagChange} className="ms-2" style={{border: "none", boxShadow: "none", outline: "none", backgroundColor: "transparent", padding: "0",margin: "0", width: "auto !important", maxWidth: "inherit"}} />
          </div>
        </div>
      </div>
    );
  };

const CreateNews = () => {
    const [newtags, setNewsTags] = useState([]);
    const previewBlogImage = () => {
        const input = document.getElementById('image');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('newsimg');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const previewBlogDetailImage = () => {
        const input = document.getElementById('blog_detail_img');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('newsimg1');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return(
        <React.Fragment>
            <Head title="Form Elements" />
            <Content >
                <BlockHead size="lg" className={`d-flex`}>
                <BlockHeadContent>
                    <BlockTitle tag="h3" className="fw-normal">
                    Create News
                    </BlockTitle>
                
                </BlockHeadContent>
                <BlockHeadContent className={`ms-auto`}>
                <Link to={"/admin-manage-cms/admin-news-list"} className="btn btn-outline-light bg-white">
                        <Icon className="ni ni-list"></Icon>
                    </Link>
                </BlockHeadContent>
                </BlockHead>
                <Block size="lg">
                    <PreviewCard> 
                        <form id="admin_news_form" method="post" enctype="multipart/formdata" novalidate="novalidate">
                        <Row className="gy-4">
                            <Col sm="6">
                                <div className="form-group">
                                    <Label htmlFor="title" className="form-label">Title</Label>
                                    <div className="form-control-wrap">
                                        <Input id="title" name="title" id="title" placeholder="Input placeholder" type="text" />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <Label htmlFor="news_for" className="form-label">For</Label>
                                    <div className="form-control-wrap">
                                        <div className="form-control-select">
                                            <Input type="select" name="news_for" id="news_for">
                                                <option value="default_option">Default Option</option>
                                                <option value="1">Silocloud</option>
                                                <option value="2">Blockchain</option>
                                            </Input>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" className="">
                                <InputTags label="Tags" placeholder="Add Tags" tags={newtags} setTags={setNewsTags} />
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                <Label htmlFor="description" className="form-label">
                                    Description<span id="new-line"><small>(Enter New Line)</small></span>
                                </Label>
                                <div className="form-control-wrap">
                                    <Input className="no-resize" id="description" name="description" defaultValue="Large text area content" type="textarea" />
                                </div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="form-group">
                                    <Label htmlFor="type" className="form-label">Type</Label>
                                    <div className="form-control-wrap">
                                        <div className="form-control-select">
                                            <Input type="select" name="type" id="type">
                                                <option value="default_option">Default Option</option>
                                                <option value="0">News</option>
                                                <option value="1">Blog</option>
                                            </Input>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <Label htmlFor="author" className="form-label">Author</Label>
                                    <div className="form-control-wrap">
                                        <Input name="author" id="author" placeholder="Input placeholder" type="text" />
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" id="catagory_list" className="d-none">
                                <label ClassName="form-label" for="catagory">Catagory</label>
                                <Input type="select" ClassName="form-control form-select js-select2 select2-hidden-accessible" name="category" id="catagory" data-select2-id="catagory" tabindex="-1" aria-hidden="true">
                                    <option value="1" data-select2-id="2">TECH TRENDS</option>
                                    <option value="2">ARTIFICIAL INTELLIGENCE</option>
                                    <option value="3">PRODUCT REVIEWS</option>
                                    <option value="4">CLOUD COMPUTING</option>
                                    <option value="5">TECH NEWS</option>
                                    <option value="6">TECH CONFERENCES</option>
                                </Input>
                                <span ClassName="select2 select2-container select2-container--default" dir="ltr" data-select2-id="1" style={{width: 'auto'}}>
                                    <span ClassName="selection">
                                        <span ClassName="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-catagory-container">
                                            <span ClassName="select2-selection__rendered" id="select2-catagory-container" role="textbox" aria-readonly="true" title="TECH TRENDS">TECH TRENDS</span>
                                            <span ClassName="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                                        </span>
                                    </span>
                                    <span ClassName="dropdown-wrapper" aria-hidden="true"></span>
                                </span>
                            </Col>
                            <Col sm="6" className="">
                                <Row>
                                    <Col sm="6" className="">
                                        <div className="form-group">
                                            <div className="form-group text-center">
                                                <Label className="form-label">Blog Image</Label>
                                                <div className="form-control-wrap">
                                                    <div className="form-file d-flex justify-content-center">
                                                        <img src="https://demo.silocloud.org/assets/frontend/site_settings/default_package_img.png" id="newsimg" alt="Profile" style={{width: '200px', height: '126px'}}/>
                                                        <Input name="image" id="image" accept="image/*" className="profile-input1 btn btn-primary" type="file" onChange={previewBlogImage} style={{cursor: 'pointer', display: 'block', height: '100px',left: '0',opacity: '0 !important', position: 'absolute',top: '0', right: '0', width: '300px', height: '250px'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" className="">
                                        <div className="form-group">
                                            <div className="form-group text-center">
                                                <Label className="form-label">Blog Detail Image</Label>
                                                <div className="form-control-wrap">
                                                    <div className="form-file d-flex justify-content-center">
                                                        <img src="https://demo.silocloud.org/assets/frontend/site_settings/default_package_img.png" id="newsimg1" alt="Profile" style={{width: '200px', height: '126px'}} />
                                                        <Input name="blog_detail_img" id="blog_detail_img" accept="image/*" className="profile-input1 btn btn-primary" type="file" onChange={previewBlogDetailImage} style={{cursor: 'pointer', display: 'block', height: '100px',left: '0',opacity: '0 !important', position: 'absolute',top: '0', right: '0', width: '300px', height: '250px'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <div class="text-center mt-5">
                                <button type="submit" class="btn  btn-primary" id="save_admin_news"> Save</button>
                            </div>
                            <input type="hidden" name="csrf_test_localhost" value="56967733dd25f958ab44a7dc7c0efa45"/>
                        </Row>
                        </form>
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    );
};
export default CreateNews;