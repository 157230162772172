import React, { useState } from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import MediaInformation from "./MediaInformation";
import ContactInformation from "./ContactInformation";
import DoctorInformation from "./DoctorInformation";
import {Label,Input, Row, Col } from "reactstrap";
import {
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    Icon,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";
  // hooks
import useShowHidePassword from "../../hooks/use-show-hide-password";
import PersonalInformation from "./PersonalInformation";

export default function EditUser() {
    const { showPassword, showRePassword, togglePassword} = useShowHidePassword()
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
   

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }

    return (
        // <p>Name: {user.name}</p>
        <React.Fragment>
            <Head title="Form Elements" />
            <Content >
                <BlockHead size="lg" className={`d-flex`}>
                    <BlockHeadContent>
                        <BlockTitle tag="h3" className="fw-normal">
                        Edit User/<strong className="text-primary small">{user.name}</strong>
                        </BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className={`ms-auto`}>
                        <Link to={"/user-list"} className="btn btn-outline-light bg-white"><em className="icon ni ni-list"></em></Link>
                    </BlockHeadContent>
                </BlockHead>
                    <PreviewCard>
                        <Row className="g-gs">
                            <Col lg="6" className="">
                                <div className="card card-bordered h-100">
                                    <div className="card-inner">
                                        <div className="card-head">
                                            <h5 className="card-title">Social Information</h5>
                                        </div>
                                        <form action="#">
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <Icon className="ni ni-facebook-f"/>
                                                        </span>
                                                        </div>
                                                        <Input type="text" className="form-control" placeholder="Enter the Facebook URL" id="facebook" name="facebook" value="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <Icon className="ni ni-twitter" />
                                                        </span>
                                                        </div>
                                                        <Input type="text" className="form-control" placeholder="Enter the Twitter URL" id="twitter" name="twitter" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <Icon className="ni ni-linkedin" />
                                                        </span>
                                                        </div>
                                                        <Input type="text" className="form-control" placeholder="Enter the Linkedin URL" id="linkedin" name="linkedin" value=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <Icon className="ni ni-pinterest" />
                                                        </span>
                                                        </div>
                                                        <Input type="text" className="form-control" placeholder="Enter the Pinterest URL" id="pinterest" name="pinterest" value="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-control-wrap">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <Icon className="icon ni ni-whatsapp" />
                                                        </span>
                                                        </div>
                                                        <Input type="text" className="form-control" placeholder="Enter the WhatsApp Number" id="whatsapp" name="whatsapp" value="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-primary">
                                                    Save Social Information
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" className="">
                                <div className="card card-bordered h-100">
                                    <div className="card-inner">
                                        <div className="card-head">
                                            <h5 className="card-title">Account - Password</h5>
                                        </div>
                                        <div className="form-group">
                                            <Label className="form-label" htmlFor="password">Change Password</Label>
                                            <div className="form-control-wrap">
                                            <div className="form-icon form-icon-right">
                                                <Icon className={`ni ${showPassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('password')}></Icon>
                                            </div>
                                            <Input type={showPassword ? "text" : "password"} className="form-control" id="password" name="password"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Label className="form-label" htmlFor="cPassword">Confirm Password</Label>
                                            <div className="form-control-wrap">
                                            <div className="form-icon form-icon-right">
                                                <Icon className={`ni ${showRePassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('re-password')}></Icon>
                                            </div>
                                            <Input type={showRePassword ? 'text' : 'password'} className="form-control" id="re-password" name="re-password" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary">Save Password</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </PreviewCard>
                    <MediaInformation />
                    <ContactInformation />
                    <PersonalInformation />
                    <DoctorInformation />
            </Content>
        </React.Fragment>
    );
}

