import React, { useState } from "react"; // Removed 'useEffect' as it's not being used
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import { Label, Input, Row, Col, FormGroup, Form, Button } from "reactstrap"; // Modified import to include FormGroup, Form, Button
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";

const CreateService = () => {
  const [newtags, setNewTags] = useState([]); 

  const previewImage = (inputId, imgId) => {
    const input = document.getElementById('service_logo_path_id1');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('logoPreivew');

    reader.onloadend = () => {
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const previewImage1 = (inputId, imgId) => {
    const input = document.getElementById('silo5_logo_path_id');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('silo5Preview');

    reader.onloadend = () => {
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const previewImage2 = (inputId, imgId) => {
    const input = document.getElementById('service_footer_logo_path_id');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('footerPreivew');

    reader.onloadend = () => {
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const previewImage3 = (inputId, imgId) => {
    const input = document.getElementById('service_bg_image_id');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('bgPreview');

    reader.onloadend = () => {
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const previewImage4 = (inputId, imgId) => {
    const input = document.getElementById('service_bg_thumb_id');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('thumbPreview');

    reader.onloadend = () => {
      img.src = reader.result;
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content>
        <Block size="lg">
          <BlockHead size="lg" className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3" className="fw-normal">
                Services
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link
                to={"/admin-manage-cms/service"}
                className="btn btn-outline-light bg-white">
                <Icon className="ni ni-list"></Icon>
              </Link>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <div className="card-inner">
                <Form id="services_form" method="post" encType="multipart/form-data" noValidate >
                    <div className="form-group">
                        <h5 className="title">Service Details</h5>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="service_name">Service Name</Label>
                                    <Input type="text" id="service_name" name="service_name" required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="trial_period">Free Trial Duration (In Days)</Label>
                                    <Input type="number" id="trial_period" name="trial_period"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="textarea" id="description" name="description" required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="service_link">Service Link</Label>
                                    <Input type="url" id="service_link" name="service_link" />
                                </FormGroup>
                                <FormGroup className="mt-1">
                                    <Label for="gender">Is It External Service?</Label>
                                    <div>
                                    <FormGroup check inline>
                                        <Label check>
                                        <Input type="radio"  id="yes" name="is_external_app"  value="1"/>{' '} Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                        <Input type="radio" id="no" name="is_external_app" value="0" />{' '} No
                                        </Label>
                                    </FormGroup>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-group">
                        <h5 className="title">Images</h5>
                        <Row className="mb-4">
                            <div className="form-group col-md-6 ">
                                <label className="form-label" for="service_logo_path">Service Logo Image<small>Prefered Size : 360px X 300px</small></label>
                                <div className="form-control-wrap pictureContainer h-300px" style={{position: 'relative',textAlign: 'center'}}>
                                    <div className="custom-file pictureWrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '4px solid #e84534'}}>
                                        <input type="file" accept="image/*" className="pictureInput" id="service_logo_path_id1" name="service_logo_path" onChange={previewImage} style={{cursor: 'pointer', display: 'block',height: '100%', left: '0',opacity: '0', position: 'absolute', top: '0', right: '0', margin: '0 auto', width: '100%'}}/>
                                        <img src="https://demo.silocloud.org/assets/banner3.jpg" className="w-auto h-100" alt="" id="logoPreivew"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label" for="silo5_logo_path">Silo5 Logo Image<small>Prefered Size : 360px X 300px</small></label>
                                <div className="form-control-wrap pictureContainer h-300px" style={{position: 'relative',textAlign: 'center'}}>
                                    <div className="custom-file pictureWrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '4px solid #e84534'}}>
                                        <input type="file" accept="image/*" className="pictureInput" id="silo5_logo_path_id" name="silo5_logo_path" onChange={previewImage1} style={{cursor: 'pointer', display: 'block',height: '100%', left: '0',opacity: '0', position: 'absolute', top: '0', right: '0', margin: '0 auto', width: '100%'}}/>
                                        <img src="https://demo.silocloud.org/assets/banner3.jpg" className="w-auto h-100" alt="" id="silo5Preview"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-6 mt-3">
                                <label className="form-label" for="service_footer_logo_path">Service Footer Logo Image<small>Prefered Size : 60px X 40px</small></label>
                                <div className="form-control-wrap pictureContainer h-300px" style={{position: 'relative',textAlign: 'center'}}>
                                    <div className="custom-file pictureWrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '4px solid #e84534'}}>
                                        <input type="file" accept="image/*" className="pictureInput" id="service_footer_logo_path_id" name="service_footer_logo_path" onChange={previewImage2} style={{cursor: 'pointer', display: 'block',height: '100%', left: '0',opacity: '0', position: 'absolute', top: '0', right: '0', margin: '0 auto', width: '100%'}}/>
                                        <img src="https://demo.silocloud.org/assets/banner3.jpg" className="w-auto h-100" alt="" id="footerPreivew"/>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="row mb-3">
                            <div className="form-group col-md-6">
                                <label className="form-label" for="service_bg_image">Service Background Image<small>Prefered Size : 1170px X 700px</small></label>
                                <div className="form-control-wrap pictureContainer h-300px" style={{position: 'relative',textAlign: 'center'}}>
                                    <div className="custom-file pictureWrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '4px solid #e84534'}}>
                                        <input type="file" accept=".jpg, .jpeg" className="pictureInput" id="service_bg_image_id" name="service_bg_image" onChange={previewImage3} style={{cursor: 'pointer', display: 'block',height: '100%', left: '0',opacity: '0', position: 'absolute', top: '0', right: '0', margin: '0 auto', width: '100%'}}/>
                                        <img src="https://demo.silocloud.org/assets/banner3.jpg" className="w-auto h-100" alt="" id="bgPreview"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="form-label" for="service_bg_thumb">Service Background Thumnail Image<small>Prefered Size : 400px X 200px</small></label>
                                <div className="form-control-wrap pictureContainer h-300px" style={{position: 'relative',textAlign: 'center'}}>
                                    <div className="custom-file pictureWrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: '4px solid #e84534'}}>
                                        <input type="file" accept=".jpg, .jpeg" className="pictureInput" id="service_bg_thumb_id" name="service_bg_thumb" onChange={previewImage4} style={{cursor: 'pointer', display: 'block',height: '100%', left: '0',opacity: '0', position: 'absolute', top: '0', right: '0', margin: '0 auto', width: '100%'}}/>
                                        <img src="https://demo.silocloud.org/assets/banner3.jpg" class="w-auto h-100" alt="" id="thumbPreview"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center mt-5">
                        <Button type="submit" color="primary" id="btn-submit">Save</Button>
                    </div>
                    <Input type="hidden" name="csrf_test_localhost" value="" />
                </Form>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CreateService;
