import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../../../../api-service";
import { Card, Grid, Typography, Button, Skeleton, Divider } from "@mui/material"; // Ensure Skeleton is imported
import Swal from "sweetalert2";

const UserKYCDetails = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserKycDetails();
  }, []);

  const userId = localStorage.getItem("UserIdForKYCDetails");

  const getUserKycDetails = async () => {
    const payload = {
      kyc_id: userId,
    };
    try {
      const response = await postRequest(`/kyc/get-kyc-data`, payload);
      const userData = response.data.coin[0];
      setUser(userData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user KYC details:", error);
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleApprove = async (id) => {
    const payload = {
      kyc_id: id,
      status: 1,
    };
    try {
      // Example API request to approve user's KYC
      await postRequest(`/kyc/kyc-approve-reject`, payload).then((res) => {
        Swal.fire({
          title: `${res.message}`,
          //   text: `${res.message}`,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin-manage-coinexchange/user-kyc-data");
      });
    } catch (error) {
      console.error("Error approving user KYC:", error);
    }
  };

  const handleReject = async () => {
    try {
      // Example API request to reject user's KYC
      const response = await postRequest(`/kyc/reject`, { user_id: userId });
      // Handle success response
      console.log("User KYC Rejected:", response.data);
      // Optionally, you can update local state or trigger a reload of user data
    } catch (error) {
      console.error("Error rejecting user KYC:", error);
    }
  };

  return (
    <Content page="component">
      <Block size="lg" className="package-list-table">
        <BlockHead className={`d-flex`}>
          <BlockHeadContent>
            <BlockTitle tag="h3">User KYC Details </BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className={`ms-auto`}>
            <Link to={-1} className="btn btn-outline-light bg-white mx-1 tooltip_toggler" title="Back">
              <Icon className="ni ni-first" />
              <span style={{ marginLeft: "-5%" }}>Back</span>
            </Link>
          </BlockHeadContent>
        </BlockHead>
      </Block>

      <Block size="lg" className="package-list-table">
        <Card className="card-kyc-detail" sx={{ mt: 5, padding: "20px" }}>
          {loading ? (
            <>
              {/* Display skeleton while loading */}
              <Skeleton
                variant="text"
                sx={{ textAlign: "center", margin: "0 auto" }}
                animation="wave"
                width="40%"
                height={70}
              />
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />

                  {/* Add more Skeleton items for other fields */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />
                  <Skeleton variant="text" animation="wave" width="100%" height={40} />

                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <Skeleton variant="text" animation="wave" width="30%" height={40} />
                      <Skeleton variant="rounded" animation="wave" width={150} height={125} sx={{ m: 1 }} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex" }}>
                      <Skeleton variant="text" animation="wave" width="30%" height={40} />
                      <Skeleton variant="rounded" animation="wave" width={150} height={125} sx={{ m: 1 }} />
                    </Grid>
                  </Grid>

                  {/* Add more Skeleton items for other fields */}
                </Grid>
              </Grid>
            </>
          ) : (
            // Display actual user data once loaded
            <>
              <Typography variant="h4" sx={{ textAlign: "center", margin: "0 auto" }}>
                {`${user.first_name} ${user.last_name}`}
              </Typography>
              <Divider sx={{ mt: 2 }} />
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <span className="bold">Date of Birth:</span> {user.dob}
                  </Typography>
                  <Typography>
                    <span className="bold">Phone:</span> {user.phone_no}
                  </Typography>
                  <Typography>
                    <span className="bold">Email:</span> {user.email}
                  </Typography>
                  <Typography>
                    <span className="bold">Country:</span> {user.country_name}
                  </Typography>{" "}
                  <Typography>
                    <span className="bold">State:</span> {user.state_name}
                  </Typography>
                  {/* Add more fields as per your user KYC data structure */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <span className="bold">Address 1:</span> {user.address_1}
                  </Typography>
                  <Typography>
                    <span className="bold">Address 2:</span> {user.address_2}
                  </Typography>
                  <Typography>
                    <span className="bold">Zipcode:</span> {user.zip_code}
                  </Typography>

                  <Typography>
                    <span className="bold text-start" style={{ marginTop: "-50px" }}>
                      Document:
                    </span>
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>Front side:</Typography>
                      <img src={user.doc_front} alt="" height={125} width={150} style={{ borderRadius: "15px" }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Back side:</Typography>
                      <img src={user.doc_front} alt="" height={125} width={150} style={{ borderRadius: "15px" }} />
                    </Grid>
                  </Grid>

                  {/* Add more fields as per your user KYC data structure */}
                </Grid>
              </Grid>
              {/* Additional sections for displaying document photos, etc. */}
              <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
                <Grid item>
                  <Button variant="contained" color="success" onClick={() => handleApprove(user.id)}>
                    Approve
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="error" onClick={() => handleReject(user.id)}>
                    Reject
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </Block>
    </Content>
  );
};

export default UserKYCDetails;
