import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Content from "../../../layout/content/Content";
import "../../../assets/css/AddUser.css";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import Head from "../../../layout/head/Head";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../api-service";
import Swal from "sweetalert2";
import TableloadingData from "../../TableloadingData";
import {
  Dialog,
  DialogActions,
  DialogContent,
  // Button,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import addCoin from "../../../images/Add Coin White.png";

import "../../../../src/main.css";
import { useTheme } from "../../../layout/provider/Theme";

const CoinList = () => {
  const theme = useTheme();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataForPagination, setDataForPagination] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    getCoinData();
  }, [itemsPerPage, currentPage, searchKeyword]);

  const getCoinData = async () => {
    try {
      setLoading(true);
      // const res = await getRequest(
      //   `/get-coin?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`
      // );
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        search_keyword: searchKeyword,
      };

      const res = await postRequest(`/coin/get-admin-coin`, payload);
      setData(res.data.coin);
      setDataForPagination(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coin data:", error);
      setLoading(false);
    }
  };

  const handleEdit = (coinId) => {
    setSelectedCoin(coinId);
    localStorage.setItem("coinIdForEdit", coinId);
    navigate("/admin-manage-coinexchange/editCoin");
  };

  const handleDelete = (coinId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });

        const payload = {
          coin_id: coinId,
        };

        await postRequest("/coin/delete-coin", payload);
        getCoinData();
      }
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset to first page when changing search keyword
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  let totalPages = 0;
  {
    searchKeyword
      ? (totalPages = Math.ceil(data?.length / itemsPerPage))
      : (totalPages = Math.ceil(dataForPagination?.count / itemsPerPage));
  }

  const handleCoinDetails = (id) => {
    localStorage.setItem("IDForCoinDetails", id);
    navigate(`/admin-manage-coinexchange/coinDetails/${id}`);
  };

  const handleSelectedCoinIdIsPresent = (id) => {
    if (id) {
      handleCoinDetails(id);
    }
  };

  const [modal1, setModal1] = useState(false);
  const toggleModal1 = () => {
    setModal1(!modal1);
    setShowFullDescription(!showFullDescription);
  };

  const [selectedCoin1, setSelectedCoin1] = useState(null);

  const handleCoinDetails1 = (coin) => {
    setSelectedCoin1(coin);
    setModal1(true);
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const descriptionToShow = showFullDescription
    ? selectedCoin1?.description
    : `${selectedCoin1?.description.slice(0, 200)}...`;

  return (
    <div>
      <React.Fragment>
        <Head title="Basic Tables" />
        <Content page="component">
          <Block size="lg" className="package-list-table">
            <BlockHead className={`d-flex`}>
              <BlockHeadContent>
                <BlockTitle tag="h3">Coin List</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent className={`ms-auto`}>
                <Link
                  to={"/admin-manage-coinexchange/addCoin"}
                  className="btn btn-outline-light bg-white mx-1 tooltip_toggler"
                  title="Add Coin"
                >
                  <Icon className="ni ni-plus"></Icon>
                  <span style={{ marginLeft: "-5%" }}>Add Coin</span>
                </Link>
              </BlockHeadContent>
            </BlockHead>

            <Card className="card-preview" style={{ padding: "15px" }}>
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <div style={{ width: "20%", margin: "10px 0" }}>
                  <Input
                    type="search"
                    placeholder="Search by name"
                    value={searchKeyword}
                    onChange={handleSearchInputChange}
                  />
                </div>

                <div className="d-flex">
                  <Label className="me-2">Show</Label>
                  <Input
                    type="select"
                    name="select"
                    id="itemsPerPageSelect"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Input>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <Table
                  striped
                  className="all-table"
                  style={{ minWidth: "fit-content", textAlign: "center", border: "0.5px solid #d5d7db" }}
                >
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Coin Logo</th>
                      <th>Coin Name</th>
                      <th>Coin Symbol</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>1h%</th>
                      <th>24h%</th>
                      <th>7d%</th>
                      <th>Market Cap</th>
                      <th>Volume</th>
                      <th>Circulation Supply</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      Array.from({ length: 9 }).map((_, index) => (
                        <tr key={index} style={{ textAlign: "center" }}>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", mr: 1, ml: 3 }} width={40} />
                          </td>
                          <td>
                            <Skeleton variant="circular" sx={{ mr: 2, mt: 0.5, ml: 3 }} width={50} height={45} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem", ml: 2 }} width={100} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={110} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={110} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={40} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={40} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={40} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={40} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={120} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={90} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={150} />
                          </td>
                          <td>
                            <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={70} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        {/* <Table
                          striped
                          className="all-table table"
                          style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}
                        > */}

                        {data?.map((coin, index) => (
                          <>
                            <tr key={coin.id} style={{ cursor: "pointer" }} onClick={() => handleCoinDetails1(coin)}>
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td style={{ cursor: "pointer" }}>
                                <img
                                  src={coin.coin_logo ? coin.coin_logo : addCoin}
                                  alt="coin logo"
                                  style={{ maxWidth: "50px", maxHeight: "50px", border: "none" }}
                                />
                              </td>
                              <td style={{ maxWidth: "300px" }}>{coin.coin_name}</td>
                              <td>{coin.coin_symbol}</td>
                              <td
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {/* {coin.description} */}
                                <Tooltip style={{ border: "none" }} title={coin.description} placement="left">
                                  <span>{coin.description}</span>
                                </Tooltip>
                              </td>
                              <td>{coin.price}</td>
                              <td>{coin.one_h}%</td>
                              <td>{coin.twenty_four_h}%</td>
                              <td>{coin.seven_d}%</td>
                              <td>{coin.market_cap}</td>
                              <td>{coin.volume}</td>
                              <td>{coin.circulation_supply}</td>
                              <td
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div style={{ display: "flex", justifyContent: "space-evenly", border: "none" }}>
                                  <Icon
                                    name="edit"
                                    onClick={() => handleEdit(coin.id)}
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "5px",
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                  />
                                  <Icon
                                    name="trash"
                                    onClick={() => handleDelete(coin.id)}
                                    style={{ fontSize: "18px", marginRight: "5px", cursor: "pointer" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                        {/* </Table> */}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
              {/* Pagination */}
              <div className="d-flex justify-content-between mt-2">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink first onClick={() => paginate(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                  </PaginationItem>
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <PaginationItem key={index} active={index + 1 === currentPage}>
                      <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink last onClick={() => paginate(totalPages)} />
                  </PaginationItem>
                </Pagination>
              </div>
            </Card>
          </Block>
        </Content>
      </React.Fragment>

      <Dialog open={modal1} onClose={toggleModal1} maxWidth="md" fullWidth sx={{textAlign:'center'}}>
        {/* <DialogTitle>Coin Details</DialogTitle> */}
        <div style={{ textAlign: "end", backgroundColor: theme.skin==='dark'?"#2f3030":'#fff', color: theme.skin==='dark'?"#fff":'#000', height: "0px" }}>
          <Button
            onClick={toggleModal1}
            color="danger"
            style={{
              // position: "absolute",
              borderBottomLeftRadius: "5px",
              textAlign: "center",
              height: "23px",
              marginTop: "-1px",
              // width: "30px",

              // backgroundColor: "transparent",
            }}
          >
            X
          </Button>
        </div>

        <DialogContent dividers sx={{backgroundColor: theme.skin==='dark'?"#2f3030":'#fff', color: theme.skin==='dark'?"#fff":'#000'}}>
          {selectedCoin1 && (
            <>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px 0" }}
              >
                <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center", padding: "0 2%" }}>
                  <div style={{ marginRight: "2%" }}>
                    <img
                      src={selectedCoin1.coin_logo}
                      alt=""
                      style={{ borderRadius: "50%", height: "60px", width: "60px" }}
                    />
                  </div>
                  <div style={{ textAlign: "start" }}>
                    <h5 style={{ margin: 0 }}>{selectedCoin1.coin_name}</h5>
                    <h6 style={{ margin: 0 }}>{selectedCoin1.coin_symbol}</h6>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <Button
                    size="small"
                    color="info"
                    onClick={() => handleSelectedCoinIdIsPresent(selectedCoin1.id)}
                    style={{
                      borderRadius: "3px",
                      height: "30px",
                      marginRight: "3px",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Icon name={"info"} />
                    <span>Info</span>
                  </Button>
                </Grid>
              </Grid>

              <hr style={{ marginTop: "0px" }} />

              <div style={{ padding: "10px", marginTop: "2%", height: "fit-content" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} style={{ maxWidth: "100%", padding: "10px" }}>
                    <div style={{ overflowX: "auto" }}>
                      <table className="table table-bordered  mt-4" style={{ width: "100%", overflowX: "auto" }}>
                        <tbody>
                          <tr>
                            <td>
                              <b>Current Price</b>
                            </td>
                            <td>
                              <b>1 Hr</b>
                            </td>
                            <td>
                              <b>24 Hr</b>
                            </td>
                            <td>
                              <b>7 D</b>
                            </td>
                          </tr>
                          <tr>
                            <td>${selectedCoin1.price}</td>
                            <td>{selectedCoin1.one_h}%</td>
                            <td>{selectedCoin1.twenty_four_h}%</td>
                            <td>{selectedCoin1.seven_d}%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ display: "flex", borderLeft: "1px solid #bcc2be", marginTop: "2%" }}
                  >
                    <p style={{ textAlign: "start", marginRight: "1%" }}>
                      <span style={{ fontWeight: "bold" }}>Description:</span> {descriptionToShow}
                      <span onClick={toggleDescription} style={{ marginLeft: "1rem", cursor: "pointer", color: "red" }}>
                        {showFullDescription ? "Read less" : "Read more"}
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </div>

              <hr />

              <Grid container spacing={2} style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
                <Grid item xs={12} sm={3}>
                  <span style={{ fontWeight: "bold" }}>Circulation Supply: </span>
                  {selectedCoin1.circulation_supply}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <span style={{ fontWeight: "bold" }}>Total Investment: </span>${selectedCoin1.total_investment}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <span style={{ fontWeight: "bold" }}>Marketcap: </span>
                  {selectedCoin1.market_cap}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <span style={{ fontWeight: "bold" }}>Volume: </span>
                  {selectedCoin1.volume}
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CoinList;
