import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Row, Col, Button } from "reactstrap";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, PreviewCard, Icon, BlockHeadContent } from "../../../../components/Component";
import "../../../../assets/css/AddUser.css";
import { getRequest, postRequest } from "../../../../api-service";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";

const AddReports = () => {
  const navigate = useNavigate();
  const [coinName, setCoinName] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [reportFile, setReportFile] = useState(null);
  const [open, setOpen] = useState(false);

  const [selectedCoinId, setSelectedCoinId] = useState(Number);
  const [coinList, setCoinList] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getRequest("/reports/get-category");
        if (response.status === true) {
          setCategories(response.data.category_data);
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        Swal.fire({
          icon: "error",
          title: "An error occurred",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await getRequest(`/reports/get-sub-category`);
        if (response.status === true) {
          setSubCategories(response.data.category_data);
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        Swal.fire({
          icon: "error",
          title: "An error occurred",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    fetchSubCategories();
    getCoinData();
  }, [selectedCategoryId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("coin_id", selectedCoinId);
    formData.append("category_id", selectedCategoryId);
    formData.append("sub_category_id", selectedSubCategoryId);
    formData.append("report_file", reportFile);

    try {
      const response = await postRequest("/reports/add-reports", formData);
      setOpen(false);

      if (response.status === true) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin-manage-coinexchange/coinReports");
      } else {
        Swal.fire({
          icon: "error",
          title: response.errors[0],
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setOpen(false);
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleReportFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setReportFile(selectedFile);
  };

  const getCoinData = async () => {
    try {
      const res = await postRequest(`/coin/get-admin-coin`);
      console.log("res coin data", res);
      setCoinList(res.data.coin);
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content>
        <BlockHead size="lg" className="d-flex justify-content-between">
          <BlockHeadContent>
            <BlockTitle tag="h3">Add Report</BlockTitle>
          </BlockHeadContent>
          <Link to={"/admin-manage-coinexchange/coinReports"} className="btn btn-outline-light bg-white">
            <Icon className="ni ni-list"></Icon>
            <span style={{ marginLeft: "-5%" }}>Report List</span>
          </Link>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm="12" md="6">
                  <Label htmlFor="category" className="form-label">
                    Coin Name
                  </Label>
                  <Input
                    md="6"
                    required
                    type="select"
                    name="coinName"
                    id="coinName"
                    value={selectedCoinId}
                    onChange={(e) => {
                      const selectedId = e.target.value; // Get the selected ID
                      const selectedCoin = coinList.find((coin) => coin.id === parseInt(selectedId)); // Find the selected coin object
                      if (selectedCoin) {
                        setCoinName(selectedCoin.coin_name); // Set coin name in state
                        setSelectedCoinId(parseInt(selectedId)); // Set coin ID in state
                      }
                    }}
                  >
                    <option value="">Select Coin</option>
                    {coinList.map((coin) => (
                      <option key={coin.id} value={coin.id}>
                        {coin.coin_name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="category" className="form-label">
                      Category
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="category"
                        name="category"
                        type="select"
                        value={selectedCategoryId}
                        onChange={(e) => setSelectedCategoryId(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.category_name}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="form-group">
                    <Label htmlFor="subCategory" className="form-label">
                      Sub Category
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="subCategory"
                        name="subCategory"
                        type="select"
                        value={selectedSubCategoryId}
                        onChange={(e) => setSelectedSubCategoryId(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Sub Category
                        </option>
                        {subCategories.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.sub_category_name}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="reportFile"
                    label="Report File"
                    type="file"
                    accept=".pdf"
                    onChange={handleReportFileChange}
                    required
                  />
                </Col>
              </Row>
              <div style={{ margin: "3% 0 0 0", textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>

              <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

const FormInput = ({ id, label, type, value, placeholder, onChange, required, maxLength, accept }) => (
  <div className="form-group">
    <Label htmlFor={id} className="form-label">
      {label}
    </Label>
    <div className="form-control-wrap">
      <Input
        id={id}
        name={id}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        accept={accept}
      />
    </div>
  </div>
);

export default AddReports;
