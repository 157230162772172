import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";

import {
    Block,
    PreviewCard,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";
import {Label,Input, Col, Row } from "reactstrap";
  

 function MediaInformation() {
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
    const [videoFileName, setVideoFileName] = useState('Choose file');

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }

    const previewProfileImage = () => {
        const input = document.getElementById('profile_image');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('uploadProfileImage1');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const previewCoverImage = () => {
        const input = document.getElementById('cover_photo');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('uploadCoverImage');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const previewCoverVideo = (event) => {
        const files = event.target.files;
        let fileName = files.length > 0 ? files[0].name : 'Choose file';

        setVideoFileName(fileName);
    };

    return (
        // <p>Name: {user.name}</p>
    <Block size="lg">
        <PreviewCard>
            <div className="card-head">
                <h5 className="card-title">Media Information</h5>
            </div>
            <Row className="g-4" style={{ minHeight: '250px' }}>
                <Col lg="3" className="">
                    <div className="form-group">
                        <Col sm="6" className="w-100 d-flex justify-content-center">
                            <div className="form-group text-center">
                                <Label className="form-label">Choose profile image</Label>
                                <div className="form-control-wrap">
                                    <div className="form-file d-flex justify-content-center">
                                        <img src="https://demo.silocloud.org/assets/frontend/site_settings/user_profile_avatar.png" id="uploadProfileImage1" alt="Profile" />
                                        <Input name="user_image" id="profile_image" accept="image/*" className="profile-input1 btn btn-primary" type="file" onChange={previewProfileImage} style={{cursor: 'pointer', display: 'block', height: '100px',left: '0',opacity: '0 !important', position: 'absolute',top: '0', right: '0', width: '300px', height: '250px'}}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col lg="3" className="">
                    <div className="form-group">
                        <Label className="form-label" htmlFor="default-06">Upload a cover video</Label>
                        <div className="form-control-wrap">
                            <div className="form-file">
                                <Input name="cover_video" id="cover_video" type="file" multiple="" className="form-file-input" onChange={previewCoverVideo} style={{ display: 'none' }}/>
                                {/* <Label className="form-file-label" htmlFor="cover_video">Choose file</Label> */}
                                <Label className="form-file-label" htmlFor="cover_video" style={{border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem .75rem', width: '100%', marginTop: '5px', display: 'inline-block'}}>
                                    {videoFileName}
                                </Label>
                            </div>
                        </div>
                    </div>
                    <div className="video">
                        <Label className="form-label">Existing Video</Label>
                        <div className="ratio ratio-16x9">
                            <video src="https://demo.silocloud.org/assets/cloud/videos/intro_video.mp4" muted autoPlay></video>
                        </div>
                    </div>
                </Col>
                <Col lg="6" className="">
                    <div className="form-group">
                        <Col sm="4" className="w-100  d-flex justify-content-center">
                            <div className="form-group text-center">
                                <Label className="form-label">Choose a cover photo (Recommended size 970*250)</Label>
                                <div className="form-control-wrap">
                                    <div className="form-file d-flex justify-content-center">
                                        <img src="https://demo.silocloud.org/assets/cloud/images/default_cover.jpg" id="uploadCoverImage" alt="Cover" style={{width: '970px', height: '250px', overflow: 'hidden'}} />
                                        <Input name="cover_image" id="cover_photo" accept="image/*" className="cover-input1 btn btn-primary" type="file" onChange={previewCoverImage}  style={{cursor: 'pointer', display: 'block', height: '250px',left: '0',opacity: '0 !important', position: 'absolute',top: '0', right: '0', minHeight: '200px' }} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Col>
            </Row>
            <Row className="gy-4 mt-5">
                <Col sm="6" className="">
                    <div className="form-group">
                        <Label className="form-label" for="select_image_video">Public cover Image/Video priority</Label>
                        <div className="form-control-wrap ">
                            <div className="form-control-select">
                                <select className="form-control" id="select_image_video">
                                    <option value="1">Cover Image</option>
                                    <option value="2">Cover Video File</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Col className="col-12 mt-5">
                <div className="form-group">
                    <button type="submit" id="saveMediaInformation" className="btn  btn-primary">Save Media Information</button>
                </div>
            </Col>
        </PreviewCard>
    </Block>
    );
}
export default MediaInformation;


