import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Label, Input, Row, Col, Button, FormGroup, Spinner } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, PreviewCard, Icon, BlockHeadContent } from "../../../components/Component";
import axios from "axios"; // Import Axios

import "../../../assets/css/AddUser.css";
import { postRequest, getRequest } from "../../../api-service";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";

const EditNews = () => {
  const navigate = useNavigate();

  const [coinName, setCoinName] = useState("");
  const [coinList, setCoinList] = useState([]);
  const [newsTitle, setNewsTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [newsImage, setNewsImage] = useState(""); // Assuming you want to add an image field for news as well
  const [imagePreview, setImagePreview] = useState(""); // State for image preview
  const [selectedCoinId, setSelectedCoinId] = useState(Number);
  const [imageName, setImageName] = useState("");
  console.log("imageName", imageName);

  const [open, setOpen] = useState(false); // State for backdrop

  useEffect(() => {
    getCoinData();
    // Fetch news data using newsId
    fetchNewsData();
  }, []);

  const getCoinData = async () => {
    try {
      const res = await postRequest(`/coin/get-admin-coin`);
      console.log("res coin data", res);
      setCoinList(res.data.coin);
    } catch (error) {
      console.error("Error fetching coin data:", error);
    }
  };
  const newsID = localStorage.getItem("newsIdForEdit");

  const fetchNewsData = async () => {
    try {
      // Fetch news data based on id

      const payload = {
        id: parseInt(newsID),
      };
      const res = await postRequest(`/coin/get-admin-news`, payload);
      const newsData = res.data.News[0]; // Assuming the response contains news data
      console.log("newsData", newsData);
      setNewsTitle(newsData.title);
      setDescription(newsData.description);
      setAuthorName(newsData.author);
      setSelectedCoinId(newsData.coin_id);
      // Fetch coin name based on selectedCoinId
      const selectedCoin = coinList.find((coin) => coin.id === newsData.coin_id);
      if (selectedCoin) {
        setCoinName(selectedCoin.coin_name);
      }
      // Fetch news image if available
      // Assuming the response contains the news image URL
      setNewsImage(newsData.news_img);

      const imageUrl = newsData.news_img;
      setNewsImage(imageUrl);
      setImageName(imageUrl.split("/").pop()); // Extract and set the image name
      // setImagePreview(newsData.news_img); // Set image preview
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  // Define handleSubmit as asynchronous
  // Define handleSubmit as asynchronous
  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!selectedCoinId) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Coin Name is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!newsTitle) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "News Title is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!description) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Description is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!authorName) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Author Name is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // } else if (!newsImage) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "News Image is required.",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   return;
    // }

    const formData = new FormData();
    formData.append("news_id", newsID); // Add news id to update the correct news
    formData.append("coin_id", selectedCoinId);
    formData.append("title", newsTitle);
    formData.append("description", description);
    formData.append("author", authorName);

    // Check if a new image is selected
    if (typeof newsImage === "object") {
      formData.append("news_img", newsImage); // Append the raw binary data
    }

    setOpen(true); // Show the backdrop when form submission starts

    try {
      const response = postRequest("/coin/update-news", formData).then((res) => {
        if (res.status === true) {
          setOpen(false); // Close the backdrop when the response is received

          Swal.fire({
            icon: "success",
            title: `${res.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin-manage-coinexchange/newsList");
        } else {
          setOpen(false); // Close the backdrop when the response is received

          Swal.fire({
            icon: "error",
            title: `${res.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } catch (error) {
      console.error("Error updating coin data:", error);
    }
  };

  const handleImageChange = (e) => {
    // Set the selected image to state and show preview
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setNewsImage(selectedImage);
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
    } else {
      // Handle the case when no file is selected
      setNewsImage(""); // Clear the news image state
      setImagePreview(""); // Clear the image preview state
    }
  };

  const handleCoinName = (id) => {
    console.log(id);
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content>
        <BlockHead size="lg" className={`d-flex justify-content-between`}>
          <BlockHeadContent>
            <BlockTitle tag="h3">Edit News </BlockTitle>
          </BlockHeadContent>
          <Link to={"/admin-manage-coinexchange/newsList"} className="btn btn-outline-light bg-white">
            <Icon className="ni ni-list"></Icon>
            <span style={{ marginLeft: "-5%" }}>News List</span>
          </Link>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm="12">
                  <FormGroup>
                    <Label htmlFor="coinName" className="form-label">
                      Coin Name
                    </Label>
                    <Input
                      required
                      type="select"
                      name="coinName"
                      id="coinName"
                      value={selectedCoinId}
                      onChange={(e) => {
                        const selectedId = e.target.value; // Get the selected ID
                        const selectedCoin = coinList.find((coin) => coin.id === parseInt(selectedId)); // Find the selected coin object
                        if (selectedCoin) {
                          setCoinName(selectedCoin.coin_name); // Set coin name in state
                          setSelectedCoinId(parseInt(selectedId)); // Set coin ID in state
                        }
                      }}
                    >
                      <option value="">Select Coin</option>
                      {coinList.map((coin) => (
                        <option key={coin.id} value={coin.id}>
                          {coin.coin_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <div className="form-group">
                    <Label htmlFor="newsTitle" className="form-label">
                      News Title
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="newsTitle"
                        name="newsTitle"
                        placeholder="News Title"
                        type="text"
                        maxLength={60}
                        value={newsTitle}
                        onChange={(e) => setNewsTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group">
                    <Label htmlFor="description" className="form-label">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="description"
                        name="description"
                        placeholder="Description"
                        type="textarea"
                        maxLength={3000}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group">
                    <Label htmlFor="authorName" className="form-label">
                      Author Name
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        required
                        id="authorName"
                        name="authorName"
                        placeholder="Author Name"
                        maxLength={50}
                        type="text"
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group">
                    <Label htmlFor="newsImage" className="form-label">
                      News Image
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="newsImage"
                        name="newsImage"
                        placeholder={imageName}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        hidden
                      />
                    </div>
                    <label className="custom-file-label" htmlFor="newsImage">
                      {imageName} or <span style={{ color: "#E14954" }}>Choose file</span>
                    </label>
                    <div>
                      {/* Show image preview */}
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="img Preview"
                          height={100}
                          width={100}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      ) : (
                        <img
                          // src={`https://api.silocloud.io/get-file-content/public/${newsImage}`}
                          src={newsImage}
                          alt="News Preview"
                          height={100}
                          width={100}
                          style={{ borderRadius: "5%", marginTop: "1%" }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ margin: " 3% 0 0 0", textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </div>
            </form>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default EditNews;
