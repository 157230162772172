import React, { useState, useEffect } from "react";
import { Table, Pagination, PaginationItem, PaginationLink, Card, Input, Label } from "reactstrap";
import Content from "../../../../layout/content/Content";
import "../../../../assets/css/AddUser.css";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import { getRequest, postRequest } from "../../../../api-service";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../../../../layout/provider/Theme";

const CoinReports = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dataForPagination, setDataForPagination] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [open, setOpen] = useState(false); // State for backdrop

  useEffect(() => {
    getCoinReportsData();
  }, [itemsPerPage, currentPage, searchKeyword]);

  const getCoinReportsData = async () => {
    try {
      setLoading(true);
      const payload = {
        page: currentPage,
        limit: itemsPerPage,
        search_keyword: searchKeyword,
      };

      const res = await postRequest(
        `/reports/get-admin-reports?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`
      );
      setData(res.data.coin);
      setDataForPagination(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coin reports data:", error);
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset to first page when changing search keyword
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const handleReportDetails = (report) => {
    setSelectedReport(report);
    setModal(true);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  let totalPages = Math.ceil(dataForPagination?.count / itemsPerPage);
  const navigate = useNavigate();
  const handleUpdateReportDetails = (id) => {
    navigate(`/admin-manage-coinexchange/updateReports/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = { report_id: id };
        postRequest("/reports/delete-reports", payload).then(
          () =>
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }),
          getCoinReportsData()
        );
      }
    });
  };

  return (
    <div>
      <React.Fragment>
        <Head title="Coin Reports" />
        <Content page="component">
          <Block size="lg" className="package-list-table">
            <BlockHead className={`d-flex`}>
              <BlockHeadContent>
                <BlockTitle tag="h3">Coin Reports</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent className={`ms-auto`}>
                <Link
                  to={"/admin-manage-coinexchange/addReports"}
                  className="btn btn-outline-light bg-white mx-1 tooltip_toggler"
                  title="Add Report"
                >
                  <Icon className="ni ni-plus"></Icon>
                  <span style={{ marginLeft: "-5%" }}>Add Report</span>
                </Link>
              </BlockHeadContent>
            </BlockHead>

            <Card className="card-preview" style={{ padding: "15px" }}>
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <div style={{ width: "20%", margin: "10px 0" }}>
                  <Input
                    type="search"
                    placeholder="Search by coin name"
                    value={searchKeyword}
                    onChange={handleSearchInputChange}
                  />
                </div>

                <div className="d-flex">
                  <Label className="me-2">Show</Label>
                  <Input
                    type="select"
                    name="select"
                    id="itemsPerPageSelect"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </Input>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <Table
                  striped
                  className="all-table"
                  style={{ minWidth: "fit-content", textAlign: "center", border: "0.5px solid #d5d7db" }}
                >
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Coin Name</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>Report</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading
                      ? Array.from({ length: itemsPerPage }).map((_, index) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            {Array.from({ length: 6 }).map((_, cellIndex) => (
                              <td key={cellIndex}>
                                <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={"100%"} />
                              </td>
                            ))}
                          </tr>
                        ))
                      : data?.map((report, index) => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={report.id}
                              // onClick={() => handleReportDetails(report)}
                            >
                              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                              <td style={{ minWidth: "170px" }}>{report.coin_name}</td>
                              <td style={{ minWidth: "150px", textTransform: "capitalize" }}>{report.category_name}</td>
                              <td style={{ textTransform: "capitalize" }}>{report.sub_category_name}</td>
                              <td>
                                {/* <Button size="medium" onClick={() => handleReportDetails(report)}> */}
                                <Icon
                                  name={"file-text"}
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "5px",
                                    cursor: "pointer",
                                    border: "none",
                                  }}
                                  onClick={() => handleReportDetails(report)}
                                />
                                {/* </Button> */}
                              </td>

                              <td>
                                {/* <Button size="small"> */}
                                {/* <Icon name={"edit"} onClick={() => handleUpdateReportDetails(report.id)} />
                                {/* </Button> */}
                                {/* <Icon name={"trash"} /> */}
                                <div style={{ display: "flex", justifyContent: "space-evenly", border: "none" }}>
                                  <Icon
                                    name="edit"
                                    onClick={() => handleUpdateReportDetails(report.id)}
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "5px",
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                  />
                                  <Icon
                                    name="trash"
                                    onClick={() => handleDelete(report.id)}
                                    style={{ fontSize: "18px", marginRight: "5px", cursor: "pointer" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </div>
              {/* Pagination */}
              <div className="d-flex justify-content-between mt-2">
                <Pagination>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink first onClick={() => paginate(1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                  </PaginationItem>
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <PaginationItem key={index} active={index + 1 === currentPage}>
                      <PaginationLink onClick={() => paginate(index + 1)}>{index + 1}</PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= totalPages}>
                    <PaginationLink last onClick={() => paginate(totalPages)} />
                  </PaginationItem>
                </Pagination>
              </div>
            </Card>
          </Block>

          {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="error" />
          </Backdrop> */}
        </Content>
      </React.Fragment>

      {/* Modal for displaying coin report */}
      <Dialog open={modal} onClose={toggleModal} maxWidth="md" fullWidth sx={{ textAlign: "center" }}>
        <h3 style={{color: theme.skin==='dark' ? '#fff':'#000', backgroundColor: theme.skin==='dark'?"#2f3030":'#fff'}}>Coin Report</h3>
        <div style={{ position: "absolute", top: 15, right: 15 }}>
          <Button size="small" color="error" variant="contained" onClick={toggleModal}>
            X
          </Button>
        </div>
        <DialogContent dividers>
          {selectedReport && (
            <div>
              <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {selectedReport.report_file ? (
                    open ? (
                      <CircularProgress color="error" />
                    ) : (
                      <iframe
                        src={selectedReport.report_file}
                        width="100%"
                        height="500px"
                        title="Coin Report PDF"
                        style={{ border: "none" }}
                      ></iframe>
                    )
                  ) : (
                    <p>No report available</p>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CoinReports;
