import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Homepage from "../pages/Homepage";
import ChartWidgets from "../pages/components/widgets/ChartWidgets";
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";
import { getCookie } from "../utils/Utils";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import UserDatatable from "../pages/user/UserList";
import AdminManageCMS from "../pages/user/AdminManageCMS";
import AddUser from "../pages/user/AddUser";
import EditUser from "../pages/user/EditUser";
import RecentUsers from "../pages/user/RecentUsers";
import UserViewDetails from "../pages/user/UserViewDetails";
import UsersMail from "../pages/user/UsersMail";
import UserLog from "../pages/user/UserLog";
import UserRoles from "../pages/user/UserRoles";
import AdminNewsList from "../pages/user/AdminNewsList";
import CreateNews from "../pages/user/CreateNews";
import PackageList from "../pages/user/PackageList";
import ServiceList from "../pages/user/ServiceList";
import Createpackage from "../pages/user/Createpackage";
import CreateService from "../pages/user/CreateService";
import SiteSettings from "../pages/user/SiteSettings";
import SiteSettingSidebar from "../pages/user/SiteSettingSidebar";
import AdminManageCoinExchange from "../pages/user/coinexchange/AdminManageCoinExchange";
import AddCoin from "../pages/user/coinexchange/AddCoin";
import CoinList from "../pages/user/coinexchange/CoinList";
import AddNews from "../pages/user/coinexchange/AddNews";
import EditCoin from "../pages/user/coinexchange/EditCoin";
import NewsList from "../pages/user/coinexchange/NewsList";
import EditNews from "../pages/user/coinexchange/EditNews";
import ReviewList from "../pages/user/coinexchange/ReviewList";
import CoinDetails from "../pages/user/coinexchange/CoinDetails";
import UserKYCData from "../pages/user/coinexchange/UserKYCData";
import UserKYCDetails from "../pages/user/coinexchange/user-kyc/UserKYCDetails";
import { CircularProgress } from "@mui/material";
import CoinReports from "../pages/user/coinexchange/coin-reports/CoinReports";
import AddReports from "../pages/user/coinexchange/coin-reports/AddReports";
import UpdateReport from "../pages/user/coinexchange/coin-reports/UpdateReport";
import ContactData from "../pages/SSDuser/ContactData";
import AdminManageContact from "../pages/SSDuser/AdminManageContact";
import ConsultationData from "../pages/SSDuser/ConsultationData";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState([]);
  const [authChecking, setAuthChecking] = useState(false);

  useEffect(() => {
    const fetchRoleFromAPI = async () => {
      try {
        const authToken = getCookie("authToken");
        const response = await fetch(`${process.env.REACT_APP_API_URL}public/get-role`, {
          method: "POST",
          headers: {
            authToken: authToken,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.status) {
            const roleId1 = responseData.data.role.id;
            console.log(roleId1);
            setRole(roleId1);

            if (roleId1 != "2") {
              Cookies.remove("authToken", { domain: process.env.REACT_APP_COOKIE_DOMAIN });

              // window.location = process.env.REACT_APP_USER_URL;
              window.location = process.env.REACT_APP_ACCOUNT_URL;
            }
          }
        } else {
          console.log("Error fetching role:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoleFromAPI();
  }, []);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (!authToken && role !== "2") {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location.href = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, [role]);

  if (authChecking || loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="text-center">
          <CircularProgress color="error" />
          <h4> Please wait, we are redirecting </h4>
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        {/* <Route index element={<UserDatatable />}></Route> */}
        <Route index element={<AdminManageCoinExchange />}></Route>
        <Route path="/admin-manage-contact" element={<AdminManageContact />}></Route>
        {/* <Route index element={<AdminManageCMS />}></Route> */}

        {/* Silo secure data */}
        <Route path="/admin-manage-cms/contact-list" element={<ContactData />}></Route>
        <Route path="/admin-manage-cms/consultation-list" element={<ConsultationData />}></Route>

        <Route path="user-list" element={<UserDatatable />}></Route>
        <Route path="/admin-manage-cms" element={<AdminManageCMS />}></Route>
        <Route path="user-list/edit/:userId" element={<EditUser />}></Route>
        <Route path="user-list/view/:userId" element={<UserViewDetails />}></Route>
        <Route path="create" element={<AddUser />}></Route>
        <Route path="/recent-user" element={<RecentUsers />}></Route>
        <Route path="/admin-user-mail" element={<UsersMail />}></Route>
        <Route path="/user-log" element={<UserLog />}></Route>
        <Route path="/user-roles" element={<UserRoles />}></Route>
        <Route path="user-list/create" element={<AddUser />}></Route>
        <Route path="/admin-manage-cms/admin-news-list/create" element={<CreateNews />}></Route>
        <Route path="/admin-manage-cms/admin-news-list" element={<AdminNewsList />}></Route>
        <Route path="/admin-manage-cms/admin-news-list/edit/:userId" element={<CreateNews />}></Route>
        <Route path="/admin-manage-cms/package" element={<PackageList />}></Route>
        <Route path="/admin-manage-cms/admin-news-list/edit/:userId" element={<CreateNews />}></Route>
        <Route path="/admin-manage-cms/package/create" element={<Createpackage />}></Route>
        <Route path="/admin-manage-cms/package/edit/:userId" element={<Createpackage />}></Route>
        <Route path="/admin-manage-cms/service" element={<ServiceList />}></Route>
        <Route path="/admin-manage-cms/service/create" element={<CreateService />}></Route>
        <Route path="site-settings-dashboard" element={<SiteSettings />}></Route>
        <Route path="/home" element={<SiteSettingSidebar />}></Route>

        {/* coin exchange */}
        <Route path="/admin-manage-coinexchange" element={<AdminManageCoinExchange />}></Route>
        <Route path="/admin-manage-coinexchange/addCoin" element={<AddCoin />}></Route>
        <Route path="/admin-manage-coinexchange/editCoin" element={<EditCoin />}></Route>
        <Route path="/admin-manage-coinexchange/coinList" element={<CoinList />}></Route>

        <Route path="/admin-manage-coinexchange/newsList" element={<NewsList />}></Route>
        <Route path="/admin-manage-coinexchange/addNews" element={<AddNews />}></Route>
        <Route path="/admin-manage-coinexchange/editNews" element={<EditNews />}></Route>

        <Route path="/admin-manage-coinexchange/reviewsList" element={<ReviewList />}></Route>

        <Route path="/admin-manage-coinexchange/coinDetails/:id" element={<CoinDetails />}></Route>

        <Route path="/admin-manage-coinexchange/user-kyc-data" element={<UserKYCData />}></Route>
        <Route path="/admin-manage-coinexchange/userKYCDetails" element={<UserKYCDetails />}></Route>

        <Route path="/admin-manage-coinexchange/coinReports" element={<CoinReports />}></Route>
        <Route path="/admin-manage-coinexchange/addReports" element={<AddReports />}></Route>
        <Route path="/admin-manage-coinexchange/updateReports/:id" element={<UpdateReport />}></Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};

export default Pages;
