import React, {useState,useRef } from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import {Row, Col } from "reactstrap";
import {
    Block,
    PreviewCard,
  } from "../../components/Component";

  const InputTags = ({ label, placeholder, tags, setTags }) => {
    const [newTag, setNewTag] = useState("");
  
    const handleTagChange = (e) => {
      setNewTag(e.target.value);
    };
  
    const handleTagKeyDown = (e) => {
      if (e.key === "Enter" && newTag.trim() !== "") {
        e.preventDefault();
        setTags([...tags, newTag.trim()]);
        setNewTag("");
      }
    };
  
    const handleTagRemove = (indexToRemove) => {
      setTags(tags.filter((_, index) => index !== indexToRemove));
    };
  
    return (
      <div className="form-group">
        <label className="form-label" htmlFor="tag">
          {label} <small>(Press ENTER to add to list)</small>
        </label>
        <div className="form-control-wrap">
          <div
            className="bootstrap-tagsinput"
            onKeyDown={handleTagKeyDown}
            tabIndex="0"
            style={{
              background: "#28202024",
              border: "2px solid #332f2f80",
              height: "91px",
              overflowY: "scroll",
            }}
          >
            {tags.map((tag, index) => (
              <span key={index} className="badge bg-primary me-1 align-items-center">
                {tag}
                <button
                  type="button"
                  className="btn-close badge-close fs-16px"
                  onClick={() => handleTagRemove(index)}
                  aria-label="Close"
                ></button>
              </span>
            ))}
            <input data-role="tagsinput" type="text" placeholder={placeholder} value={newTag} onChange={handleTagChange} className="ms-2" style={{border: "none", boxShadow: "none", outline: "none", backgroundColor: "transparent", padding: "0",margin: "0", width: "auto !important", maxWidth: "inherit"}} />
          </div>
        </div>
      </div>
    );
  };
const DoctorInformation = () => {
  const [specializations, setSpecializations] = useState([]);
  const [hospitalAffiliations, setHospitalAffiliations] = useState([]);
  const [medicalGroups, setMedicalGroups] = useState([]);
  const [languages, setLanguages] = useState([]);

    const id = useParams(); 
    const userId = parseInt(id.userId);
    // const user = userData.find(user => user.id === userId);

    const user = userData.find((user) => user.id === parseInt(userId, 10));

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }
     const previewProfileImage = () => {
        const input = document.getElementById('id_proof');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('eventThumbnailImg');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const previewProfileImage1 = () => {
        const input = document.getElementById('medical_license');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('licenseThumbnailImg');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    
  return (
    <Block size="lg">
        <PreviewCard>
          <div className="card-head">
              <h5 className="card-title">Doctor  Information</h5>
          </div>
          <form id="doctor_info">
            <Row className="g-4">
              <Col lg="6" className="">
                <div className="form-group">
                  <label className="form-label" htmlFor="first_name">First Name</label>
                  <input id="first_name" value="TempUser2" type="text" className="form-control focus" name="first_name"/>
                </div>
              </Col>
              <Col lg="6" className="">
                <div className="form-group">
                  <label className="form-label" htmlFor="last_name">Last Name</label>
                  <input id="last_name" value="" type="text" className="form-control focus" name="last_name"/>
                </div>
              </Col>
              <Col lg="6" className="">
                <div className="form-group">
                    <label className="form-label" for="specialist">Specialist</label>
                    <select name="specialist" id="specialist" className="form-control form-select" data-placeholder="Select Specialist ">  
                      <option value="General physician">General physician</option> 
                      <option value="Optometrist">Optometrist</option> 
                      <option value="Dentist">Dentist</option> 
                      <option value="Dermatologists">Dermatologists</option> 
                      <option value="Radiologists">Radiologists</option> 
                      <option value="Psychologists">Psychologists</option> 
                      <option value="Nutritionists">Nutritionists</option> 
                      <option value="Otorhinolaryngologist">Otorhinolaryngologist</option> 
                      <option value="Pulmonologists">Pulmonologists</option> 
                      <option value="Cardiologists">Cardiologists</option> 
                      <option value="Pediatricians">Pediatricians</option> 
                  </select>
                </div>
              </Col>
              <Col sm="6" className="">
                <div className="form-group">
                  <label className="form-label" htmlFor="doc_location">Location</label>
                  <input id="doc_location" value="" name="doc_location" type="text" className="form-control focus"/>
                </div>
              </Col>
              <Col sm="6" className="">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone_number">Phone Number</label>
                  <input id="phone_number" name="phone_number" type="number" className="form-control focus" value="8877442233"/>
                </div>
              </Col>
              <Col lg="6" className="">
                <div className="form-group">
                  <label className="form-label" htmlFor="education">Eduaction</label>
                  <input id="education" value="" name="education" type="text" className="form-control focus"/>
                </div>
              </Col>
              <Col lg="6" className="">
                  <InputTags label="Education Specialization" placeholder="Education Specialization" tags={specializations} setTags={setSpecializations} />
                </Col>
                <Col lg="6" className="">
                  <InputTags label="Hospital Affiliations" placeholder="Hospital Affiliations" tags={hospitalAffiliations} setTags={setHospitalAffiliations} />
                </Col>
                <Col lg="6" className="">
                  <InputTags label="Medical Group" placeholder="Medical Group" tags={medicalGroups} setTags={setMedicalGroups} />
                </Col>
                <Col lg="6" className="">
                  <InputTags label="Languages" placeholder="Languages" tags={languages} setTags={setLanguages} />
                </Col>
              <Col sm="3" className="">
                  <div className="form-group">
                      <label className="form-label" htmlFor="tag">Choose ID Proof documents</label>
                      <div className="form-control-wrap col-10">
                          <input id="id_proof" className="pictureInput" name="id_proof" type="file" onChange={previewProfileImage} required="required" accept="image/png, image/jpeg, image/gif, image/jpg" style={{cursor: 'pointer', display: 'block', height: '100%',left: '0',opacity: '0',position: 'absolute',top: '0',right: '0', margin: '0 auto', width: '100%'}}/>
                          <img id="eventThumbnailImg" src="https://demo.silocloud.org/assets/images/image-placeholder.png" alt="" width="180" height="120"/>
                      </div>
                  </div>
              </Col>
              <Col sm="3" className="">
                  <div className="form-group">
                      <label className="form-label" htmlFor="tag">Choose Medical License documents</label>
                      <div className="form-control-wrap col-10">
                          <input id="medical_license" className="pictureInput" name="medical_license" type="file" onChange={previewProfileImage1} required="required" accept="image/png, image/jpeg, image/gif, image/jpg" style={{cursor: 'pointer', display: 'block', height: '100%',left: '0',opacity: '0',position: 'absolute',top: '0',right: '0', margin: '0 auto', width: '100%'}}/>
                          <img id="licenseThumbnailImg" src="https://demo.silocloud.org/assets/images/image-placeholder.png" alt="" width="180" height="120"/>
                      </div>
                  </div>
              </Col>
              <Col sm="3" className="">
                  <label className="form-label" htmlFor="languages">Gender</label>
                  <div className="form-group">
                      <div className="custom-control custom-radio">
                          <input type="radio" id="male" name="gender" className="custom-control-input" value="male"/>
                          <label className="custom-control-label" for="male">Male</label>
                      </div>
                      <div className="custom-control custom-radio ms-3">
                          <input type="radio" id="female" name="gender" className="custom-control-input" value="female"/>
                          <label className="custom-control-label" for="female">
                          Female</label>
                      </div>
                  </div>
              </Col>
              <input type="hidden" className=" " id="id" name="id" value=""/>
              <Col className="col-12">
                  <div className="form-group">
                      <button id="saveDoctorInformation" className="btn  btn-primary">Save  Information</button>
                  </div>
              </Col>
              <input type="hidden" name="csrf_test_localhost" value="116c326094679e945563af7f1ced867b"/>
            </Row>
          </form>
        </PreviewCard>
    </Block>
  );
}
export default DoctorInformation;