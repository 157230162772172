import React from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import addCoin from "../../images/Contact_Us.png";
import newsPNG from "../../images/Consultation.png";
// import kycverify from "../../../images/kycverify.png";

// import reviewImg from "../../../assets/images/Review.png";
// import reportImg from "../../../images/reports.png";

import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard
} from "../../components/Component";
import Head from "../../layout/head/Head";
import { Link } from "react-router-dom";

const AdminManageContact = () => {
    return (
        <React.Fragment>
            <Head title="Basic Tables" />
            <Content page="component">
                <Block size="lg">
                    <BlockHead>
                        <BlockHeadContent>
                            <BlockTitle tag="h3">Admin Manage Contact Information</BlockTitle>
                        </BlockHeadContent>
                    </BlockHead>
                    <PreviewCard>
                        <div class="row g-gs">
                            <div className="col-md-6 card">
                                <Link to="/admin-manage-cms/contact-list" className="card-link">
                                    <div className="card-content text-center">
                                        <img src={addCoin} className="w-300px" alt="Contact US" />
                                        <div className="card-info py-2">
                                            <h5>Contact Information</h5>
                                            {/* <p>Add, Edit, Delete and Manage Coin Here.</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-6 card" style={{ borderLeft: "1px solid black" }}>
                                <Link to="/admin-manage-cms/consultation-list" className="card-link">
                                    <div className="card-content text-center">
                                        <img src={newsPNG} className="w-30 m-3" alt="Consultation" />
                                        <div className="card-info ms-3">
                                            <h5>Consultation Information</h5>
                                            {/* <p>Manage news articles and announcements for coin exchange here.</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    )
}

export default AdminManageContact
