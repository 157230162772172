import React from 'react'
import { Col, Row } from 'reactstrap'
import {
    Block ,
  BlockHead,
    BlockHeadContent,
    BlockTitle,
  } from "../../components/Component";
const SiteSettingSidebar = () => {
  return (
    <BlockHeadContent>
        <div className="container-fluid">
            <div className="nk-content-inner">
                <div className="nk-content-body">
                <div className="site-settings">
                    <Block className="nk-block-head-sm">
                        <div className="nk-block-between">
                            <BlockHeadContent className="">
                                <BlockTitle tag="h3" className="page-title">Cloud UI Page</BlockTitle>
                                <div className="nk-block-des text-soft d-none">
                                    <p>Welcome to Site Settings.</p>
                                </div>
                            </BlockHeadContent>
                            <BlockHeadContent></BlockHeadContent>
                        </div>
                    </Block>
                    <Block>
                        <form id="update-bg-settings-form" enctype="multipart/form-data">
                            <Row className="gy-3">
                                <div className="g">
                                    <div className="custom-control custom-control-sm custom-switch checked">
                                        <input type="checkbox" className="custom-control-input" id="home_background" name="home_background" />
                                        <div style={{ border: '1px solid #990000', paddingLeft: '20px', margin: '0 0 10px 0' }}></div>
                                        <label className="custom-control-label" htmlFor="home_background">Image</label>
                                    </div>
                                </div>
                                <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" className="custom-control-input" name="home_background" id="home_background_image" value="0" />
                                            <label className="custom-control-label" htmlFor="home_background_image">Image</label>
                                        </div>
                                    </div>
                                    <div className="g">
                                        <div className="custom-control custom-radio checked">
                                            <input type="radio" className="custom-control-input" name="home_background" id="home_background_video" value="1" checked="" />
                                            <label className="custom-control-label" htmlFor="home_background_video">Video</label>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="g-gs img">
                                <Col md="4" className="">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="title">Image</label>
                                        <div className="form-control-wrap">
                                            <div className="thumbImageContainer" id="homepagecover">
                                            <input type="file" className="btn image-input" name="homePage" id="homePage" onChange="readURL(this, 'homePageImg');" accept="image/png, image/gif, image/jpeg" data-img-tag="homePageImg" data-img="https://demo.silocloud.org/assets/frontend/site_settings/home_page_bg.png" />
                                            <img id="homePageImg" src="https://demo.silocloud.org/assets/frontend/site_settings/home_page_bg.png" alt="" width="180" height="120" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col md="4" className="">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="title">Video</label>
                                        <div className="form-control-wrap">
                                            <div className="thumbVideoContainer" id="homepagevideocover">
                                                <video id="homeVideo" controls muted autoPlay width="180" height="120">
                                                    <source src="https://demo.silocloud.org/assets/frontend/site_settings/home_backgriound_video.mp4" type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <input style={{ width: '100%', height: '100%', opacity: '0' }} type="file" className="btn video-input" name="homev" id="homev" onChange="readVideoUrl(this,'homeVideo');" accept="video/*" data-img-tag="homeVideo" data-video="https://demo.silocloud.org/assets/frontend/site_settings/home_backgriound_video.mp4" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <input type="hidden" name="csrf_test_localhost" value="" />
                        </form>
                    </Block>
                </div>
                <div className="mb-5"></div>
                    <div className="site-settings" id="user-interface">
                        <Block className="nk-block-head-sm">
                            <div className="nk-block-between">
                                <BlockHeadContent className="">
                                <h3 className="nk-block-title page-title">Cloud User Interface Page Video</h3>
                                <div className="nk-block-des text-soft d-none">
                                    <p>Welcome to Site Settings.</p>
                                </div>
                                </BlockHeadContent>
                                <BlockHeadContent></BlockHeadContent>
                            </div>
                        </Block>
                        <Block>
                            <form id="update-ui-settings" enctype="multipart/form-data">
                                <Row className="g-gs img">
                                    <Col md="4" className="4">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="title">Video</label>
                                            <div className="form-control-wrap">
                                                <div className="thumbVideoContainer" id="homepagevideocover">
                                                <video id="uiVideo" controls="" muted="" autoPlay="" width="180" height="120">
                                                    <source src="https://demo.silocloud.org/assets/frontend/site_settings/cloud_user_interface_modal_video.mp4" type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <input style={{ width: '31%', height: '22%', background: '#7e7ec7' }} type="file" className="btn video-input-1" name="start_up_video" id="start_up_video" onChange="readVideoUrl(this,'uiVideo');" accept="video/*" data-video-tag="uiVideo" data-video="https://demo.silocloud.org/assets/frontend/site_settings/cloud_user_interface_modal_video.mp4" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <input type="hidden" name="csrf_test_localhost" value="" />
                            </form>
                        </Block>
                    </div>
                </div>
            </div>
        </div>
  </BlockHeadContent>
    )
}

export default SiteSettingSidebar


                