import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Input, Label, Pagination, PaginationItem, PaginationLink, Spinner, Table } from 'reactstrap';
import { postRequest } from '../../api-service';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../components/Component';
import { Tooltip } from "@mui/material";
import Swal from 'sweetalert2';

const ConsultationData = () => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [usersData, setUsersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        getUsersData();
    }, [currentPage, itemsPerPage, searchKeyword]);

    const toggleModal = () => setModal(!modal);

    const getUsersData = async () => {
        setLoading(true);
        try {
            const payload = {
                is_request: 0
            };
            const res = await postRequest(`/silosecure/get-consultation?page=${currentPage}&limit=${itemsPerPage}&search_keyword=${searchKeyword}`, payload);

            if (res.status && res.data && res.data.data && Array.isArray(res.data.data)) {
                setUsersData(res.data.data);
                setTotalPages(Math.ceil(res.data.count / itemsPerPage));
            } else {
                console.error("Unexpected API response structure:", res);
                setUsersData([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error("Error fetching contact data:", error);
            setUsersData([]);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = useMemo(() => {
        let sortableItems = [...usersData];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                if (aValue == null) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (bValue == null) return sortConfig.direction === 'ascending' ? 1 : -1;

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return sortConfig.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                } else if (aValue instanceof Date && bValue instanceof Date) {
                    return sortConfig.direction === 'ascending'
                        ? aValue.getTime() - bValue.getTime()
                        : bValue.getTime() - aValue.getTime();
                } else {
                    return sortConfig.direction === 'ascending'
                        ? aValue - bValue
                        : bValue - aValue;
                }
            });
        }
        return sortableItems;
    }, [usersData, sortConfig]);

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handleSearchInputChange = (e) => {
        setSearchKeyword(e.target.value);
        setCurrentPage(1);
    };
    const handleDelete = async (userId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await postRequest(`/silosecure/delete-consultation-request?id=${userId}`,).then(
                        () =>
                            Swal.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success",
                                timer: 1500,
                                showConfirmButton: false,
                            }),
                        getUsersData()
                    );
                } catch (error) {
                    console.error("Failed to delete:", error);
                    Swal.fire({
                        title: "Error!",
                        text: "Failed to delete the item.",
                        icon: "error",
                    });
                }
            }
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };


    return (
        <React.Fragment>
            <Head title="Contact Requests" />
            <Content page="component">
                <Block size="lg">
                    <BlockHead>
                        <BlockHeadContent>
                            <BlockTitle tag="h3">Consultation Requests</BlockTitle>
                        </BlockHeadContent>
                    </BlockHead>
                    <Card className="card-preview border-0">
                        <div className="d-flex justify-content-between">
                            <Input
                                type="search"
                                placeholder="Search by title"
                                value={searchKeyword}
                                onChange={handleSearchInputChange}
                                style={{ width: "20%", margin: "10px 0" }}
                            />
                            <div className="d-flex align-items-center">
                                <Label className="me-2 mb-0">Show</Label>
                                <Input
                                    type="select"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                    style={{ width: "fit-content", height: "fit-content" }}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </Input>
                            </div>
                        </div>
                        <div style={{ overflowX: "auto" }}>
                            <Table
                                className="all-table"
                                striped
                                style={{ minWidth: "100%", textAlign: "center", border: "0.5px solid #d5d7db" }}
                            >
                                <thead>
                                    <tr>
                                        <th className="border-end">Sr. No.</th>
                                        <th className="border-end">
                                            Name
                                            <Button color="black" className="p-0" onClick={() => requestSort('full_name')}>
                                                {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                                            </Button>
                                        </th>
                                        <th className="border-end">
                                            Email
                                            <Button color="black" className="p-0" onClick={() => requestSort('email')}>
                                                {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                                            </Button>
                                        </th>
                                        <th className="border-end">Phone Number</th>
                                        <th className="border-end">
                                            Date of Consultation
                                            <Button color="black" className="py-0 px-1" onClick={() => requestSort('date')}>
                                                {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                                            </Button>
                                        </th>
                                        <th className="border-end">Time(UTC:24H)</th>
                                        <th className="border-end">Message</th>
                                        <th className="border-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="12">
                                                <Spinner style={{ margin: "0 auto" }} type="grow" color="danger">Loading...</Spinner>
                                            </td>
                                        </tr>
                                    ) : (
                                        sortedData.map((user, index) => (
                                            <tr key={user.id}>
                                                <td className="border-end">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td className="border-end" style={{ textAlign: "left", maxWidth: "150px" }}>
                                                    {`${user.full_name || ""}`}
                                                </td>
                                                <td className="border-end" style={{
                                                    textAlign: "left", maxWidth: "150px", overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}>
                                                    <Tooltip title={user.email} placement="left">
                                                        <a href={`mailto:${user.email}`}>{user.email}</a>
                                                    </Tooltip>
                                                </td>
                                                <td className="border-end text-center" style={{ maxWidth: "100px" }}>
                                                    <a href={`tel:${String(user.phone).startsWith('+') ? user.phone : `+${user.phone}`}`}>
                                                        {String(user.phone).startsWith('+') ? user.phone : `+${user.phone}`}
                                                    </a>
                                                </td>
                                                <td className="border-end" style={{ textAlign: "center", maxWidth: "100px" }}>{formatDate(user.date)}</td>
                                                <td className="border-end">{user?.time}</td>
                                                <td className="border-end"
                                                    style={{
                                                        textAlign: "left",
                                                        maxWidth: "100px",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                    <Tooltip title={user.message} placement="left">
                                                        {user.message}
                                                    </Tooltip>
                                                </td>
                                                <td className="border-end text-center">
                                                    <Button className='bg-transparent border-0 text-black p-1' onClick={() => handleDelete(user.id)}><Icon name="trash" /></Button>
                                                </td>
                                                {/* <td className="border-end text-center">{formatDate(user.updated_at)}</td> */}
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className="mt-3">
                            <Pagination>
                                <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                                </PaginationItem>
                                {[...Array(totalPages)].map((_, i) => (
                                    <PaginationItem active={i + 1 === currentPage} key={i}>
                                        <PaginationLink onClick={() => paginate(i + 1)}>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                                <PaginationItem disabled={currentPage === totalPages}>
                                    <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </Card>
                </Block>
            </Content>
        </React.Fragment>
    );
}

export default ConsultationData
