import React from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import { Label,Input,Row, Col } from "reactstrap";
import {
    Block,
    PreviewCard,
    Icon,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";


const ContactInformation = () => {
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
   

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }
  return (
    <Block size="lg">
        <PreviewCard>
            <div className="card-head">
                <h5 className="card-title">Contact Information</h5>
            </div>
            <Row className="g-4">
                <Col sm="6" className="">
                    <div className="form-group">
                        <Label className="form-label" htmlFor="email">Email</Label>
                        <Input name="email" value="kexal65319@ebuthor.com" type="text" className="form-control focus" id="email" required />
                    </div>
                {/* <div> */}
                    <Label className="form-label" htmlFor="phone_no">Phone Number</Label>
                    <div className="phone_number row d-flex align-center">
                        <Col className="col-3 phone_number">
                            <select className="form-control w-90 pr-1 select2-hidden-accessible" id="country_code" name="country_code" data-placeholder="Select a country" data-search="on" required>
                            <option value="93@1" data-select2-id="13">(+93)  Afghanistan</option>
                            <option value="355@2" data-select2-id="14">(+355)  Albania</option>
                            <option value="213@3" data-select2-id="15">(+213)  Algeria</option>
                            <option value="1684@4" data-select2-id="16">(+1684)  American Samoa</option>
                            <option value="376@5" data-select2-id="17">(+376)  Andorra</option>
                            <option value="244@6" data-select2-id="18">(+244)  Angola</option>
                            </select>
                        </Col>
                        <Col className="col-8 px-0">
                            <div className="form-group">
                            <Input name="phone_no" value="8877442233" type="text" className="form-control focus" id="phone_no" required />
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col  sm="6" className="pt-5 pt-sm-3">
                    <Col sm="6" className="" style={{ padding: '15px 0px' }}>
                        <div className="custom-control custom-switch checked">
                        <Input type="checkbox" className="custom-control-input" checked id="verify_button" />
                        <Label className="custom-control-label" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Unverified / Verified" htmlFor="verify_button">Account Verification Status</Label>
                        </div>
                    </Col>
                    <Col sm="6" className="" style={{ padding: '15px 0px' }}>
                        <div className="custom-control custom-switch checked">
                        <Input type="checkbox" className="custom-control-input" checked id="verify_email" />
                        <Label className="custom-control-label" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Unverified / Verified" htmlFor="verify_email">Email Verification Status</Label>
                        </div>
                    </Col>
                    <Col sm="6" className="" style={{ padding: '15px 0px' }}>
                        <div className="custom-control custom-switch checked">
                        <Input type="checkbox" className="custom-control-input" checked id="verify_mobile" />
                        <Label className="custom-control-label" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Unverified / Verified" htmlFor="verify_mobile">Mobile Verification Status</Label>
                        </div>
                    </Col>
                </Col>
                <Col className="col-12">
                    <div className="form-group">
                        <button id="saveContactInformation" className="btn btn-primary">Save Contact Information</button>
                    </div>
                </Col>
            </Row>
        </PreviewCard>
    </Block>
  );
}

export default ContactInformation;
