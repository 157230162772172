import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import {  Label, Input, Row, Col , UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { OverlineTitle } from "../../components/text/Text";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";

const Createpackage = () => {
    const [newtags, setNewsTags] = useState([]);
    const previewImage = () => {
        const input = document.getElementById('thumbnail');
        const file = input.files[0];
        const reader = new FileReader();
        const img = document.getElementById('thumbnailImg');

        reader.onloadend = () => {
            img.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return(
        <React.Fragment>
            <Head title="Form Elements" />
            <Content >
            <Block size="lg">
                <BlockHead size="lg" className={`d-flex`}>
                <BlockHeadContent>
                    <BlockTitle tag="h3" className="fw-normal">
                    Packages
                    </BlockTitle>
                
                </BlockHeadContent>
                <BlockHeadContent className={`ms-auto`}>
                    <Link to={"/admin-manage-cms/package"} className="btn btn-outline-light bg-white">
                        <Icon className="ni ni-list"></Icon>
                    </Link>
                </BlockHeadContent>
                </BlockHead>
                    <PreviewCard> 
                    <div className="card-inner">
                        <Row className="g-4">
                            <Input type="hidden" id="base_url" value="https://demo.silocloud.org/" />
                        </Row>
                        <form id="package_form">
                            <Row className="g-4">
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label" for="title">Package Name</label>
                                        <div className="form-control-wrap">
                                            <Input type="text" className="form-control" id="package_name" name="package_name" required="" value="" />

                                        </div>
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className="form-label" for="subscription_days">Subscription Days</label>
                                        <div className="form-control-wrap">
                                            <Input type="number" className="form-control" id="subscription_days" name="subscription_days" required="" value="" />
                                        </div>

                                    </div>
                                    <div className="form-group mt-2">
                                        <label className="form-label" for="title">Price</label>
                                        <div className="form-control-wrap">
                                            <Input type="number" className="form-control" id="price" name="price" required="" value="" />
                                        </div>
                                    </div>
                                    <label className="form-label mt-2" htmlFor="languages">Status</label>
                                    <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="male" name="gender" className="custom-control-input" value="male"/>
                                            <label className="custom-control-label" for="male">Active</label>
                                        </div>
                                        <div className="custom-control custom-radio ms-3">
                                            <input type="radio" id="female" name="gender" className="custom-control-input" value="female"/>
                                            <label className="custom-control-label" for="female">
                                            Inactive</label>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" className="mt-3">
                                    <Row>
                                        <Col lg="4">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Site Builder</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Site Builder - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Site Builder - Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Site Builder - Gold</label>
                                                                </div>
                                                            </li>
                                                        
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">File Manager</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Marketplace</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Marketplace 1 </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Marketplace 2</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Marketplace 3</label>
                                                                </div>
                                                            </li>
                                                        
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Open World</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Canvas</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Chat</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Community</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Connect</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo talk - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo talk - Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo talk - Gold</label>
                                                                </div>
                                                            </li>
                                                        
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo ERP</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Gold</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo ERP Free Trial</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Health</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Health - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Health - Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Health - Gold</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Live Streaming Deck</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Gold</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Platinum</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Mining</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Mining - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Mining - Silver</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Mining - Gold</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Music</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo QR Code</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Radio</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Social Media</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Silo Suite</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Suite - Trial </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Suite - Gold</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Silo Suite - Platinum</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Video Call</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call - Gold</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call - Silver</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg="4" className="mt-3">
                                            <ul className="d-flex g-3">
                                                <li>
                                                    <UncontrolledDropdown>
                                                    <DropdownToggle className="dropdown-toggle">
                                                    <div class="btn-group"><button class="btn btn-secondary">Video Call Addon Plan</button><button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle-split btn btn-secondary"><em class="icon ni ni-chevron-down"></em></button></div>
                                                        {/* <Icon name="more-h"></Icon> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ul className="link-tidy">
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Free</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call Addon - Bronze </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call Addon - Gold</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                                    <input type="checkbox" className="custom-control-input" defaultChecked={true} id="check-bl-dd" />
                                                                    <label className="custom-control-label" htmlFor="check-bl-dd">Video Call Addon - Silver</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label" for="title">Thumbnail Image</label>
                                        <div className="form-control-wrap">
                                            <div className="thumbImageContainer" id="homepagecover" style={{position: 'relative',textAlign: 'center',height: '186px',border: '4px solid #CCCCCC',background: '#818286'}}>
                                                <Input type="file" className="btn" name="thumbnail" id="thumbnail" onChange={previewImage} accept="image/png, image/gif, image/jpeg" data-img-tag="thumbnailImg" data-img="https://demo.silocloud.org/assets/frontend/site_settings/default_package_img.png" style={{cursor: 'pointer !important',display: 'block', height: '100%',left: '0',opacity: '0', position: 'absolute',top: '0',right: '0',margin: '0 auto', width: '100%'}}/>
                                                <img id="thumbnailImg" src="https://demo.silocloud.org/assets/frontend/site_settings/default_package_img.png" alt="" width="180" height="120" style={{ width: '100%', height: '179px', objectFit: 'contain' }} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" className="mt-4">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="package_type" name="package_type" value="2" />
                                        <label className="form-check-label" for="package_type">Set as Package in Game</label>
                                    </div>
                                </Col>
                                <div className="col-12">
                                    <div class="form-group mt-4">
                                        <button type="submit" class="btn  btn-primary" id="btn-submit_package"> Save </button>
                                    </div>
                                </div>
                            </Row>
                            <input type="hidden" name="csrf_test_localhost" value="a61149a2090800d37cbdec0d29018b7b" />
                        </form>
                    </div>
                </PreviewCard>
            </Block>
        </Content>
    </React.Fragment>
    );
};
export default Createpackage;

