import React, { useState } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import {ServicesDataTableColumns, Services } from "../components/table/TableData";
import { Link } from "react-router-dom";
const ServiceList = () => {
  const [file, setFile] = useState("");

  return (
    <React.Fragment>
      <Head title="Basic Tables" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
        </BlockHead>

        <Block size="lg" className="service-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Services List
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
                <Link tag="a" to={"/admin-manage-cms/package"}>
                    <button className="btn btn-primary mx-1">Package</button>
                </Link>
                <Link to={"/admin-manage-cms/service/create"} className="btn btn-outline-light bg-white mx-1 tooltip_toggler"  title="Add Packages">
                    <Icon className="ni ni-plus"></Icon>
                </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
            <ReactDataTable data={Services} columns={ServicesDataTableColumns} 
               expandableRows pagination actions className="custom-user-news-list" />
          </PreviewCard>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default ServiceList;
