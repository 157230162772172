import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
// import UserAvatar from "../../../../components/user/UserAvatar";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie } from "../../../../utils/Utils";
import { linkItems } from "../../../../components/links/LinkItemsData";
import AccountAvatar from "../../../../pages/AccountAvatar";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
const User = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const setLogout = (e) => {
    e.preventDefault();
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };
  
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        {/* <UserAvatar icon="user-alt" className="sm" /> */}
        <AccountAvatar profileName={getCookie("username", "")} />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            {/* <div className="user-avatar">
              <span>AB</span>
            </div> */}
            <AccountAvatar profileName={getCookie("username", "")} />
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "")}</span>
              <span className="sub-text">{getCookie("email", "")}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {linkItems.map((item, index) => (
              <LinkItem
                // tag="a"
                key={index}
                link={item.link}
                icon={item.icon}
                onClick={toggle}
                className={item.className}
              >
                {item.text}
              </LinkItem>
            ))}
            <li>
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <a href="/logout"> */}
            {/* <a href={`${process.env.REACT_APP_ACCOUNT_URL}logout`}> */}
            <a onClick={setLogout} style={{ cursor: "pointer" }}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
            {/* </a> */}
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
