import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Label, Input, Row, Col, Button } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, PreviewCard, Icon, BlockHeadContent } from "../../../components/Component";
import "../../../assets/css/AddUser.css";
import { postRequest } from "../../../api-service";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import Dropzone from "react-dropzone";
import { useTheme } from "../../../layout/provider/Theme";

const AddCoin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [coinName, setCoinName] = useState("");
  const [coinSymbol, setCoinSymbol] = useState("");
  const [description, setDescription] = useState("");
  const [coinImage, setCoinImage] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [price, setPrice] = useState("");
  const [marketCap, setMarketCap] = useState("");
  const [supply, setSupply] = useState("");
  const [oneHourPrice, setOneHourPrice] = useState("");
  const [twentyFourHourPrice, setTwentyFourHourPrice] = useState("");
  const [sevenDayPrice, setSevenDayPrice] = useState("");
  const [volume, setVolume] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");

  const [open, setOpen] = useState(false);
  const [isVideoSelected, setIsVideoSelected] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("coin_name", coinName);
    formData.append("coin_symbol", coinSymbol.toUpperCase());
    formData.append("description", description);
    formData.append("coin_logo", coinImage);
    if (isVideoSelected) {
      formData.append("video_url", videoFile);
    }
    formData.append("price", price);
    formData.append("market_cap", marketCap);
    formData.append("circulation_supply", supply);
    formData.append("one_h", oneHourPrice);
    formData.append("twenty_four_h", twentyFourHourPrice);
    formData.append("seven_d", sevenDayPrice);
    formData.append("volume", volume);

    try {
      const response = await postRequest("/coin/register-coin", formData);
      setOpen(false);

      if (response.status === true) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin-manage-coinexchange/coinList");
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setOpen(false);
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setCoinImage(selectedImage);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleDropChange = (acceptedFiles) => {
    const selectedImage = acceptedFiles[0];
    setCoinImage(selectedImage);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handleVideoChange = (e) => {
    const selectedVideo = e.target.files[0];
    setVideoFile(selectedVideo);
    setVideoPreview(URL.createObjectURL(selectedVideo));
    setIsVideoSelected(true);
  };

  const handleVideoCancel = () => {
    setVideoFile(null);
    setVideoPreview("");
    setIsVideoSelected(false);
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content>
        <BlockHead size="lg" className="d-flex justify-content-between">
          <BlockHeadContent>
            <BlockTitle tag="h3">Add Coin</BlockTitle>
          </BlockHeadContent>
          <Link to={"/admin-manage-coinexchange/coinList"} className="btn btn-outline-light bg-white">
            <Icon className="ni ni-list"></Icon>
            <span style={{ marginLeft: "-5%" }}>Coin List</span>
          </Link>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form onSubmit={handleSubmit}>
              <Row className="gy-4">
                <Col sm="12" md="6">
                  <FormInput
                    id="coinName"
                    label="Coin Name"
                    type="text"
                    value={coinName}
                    placeholder="Coin Name"
                    maxLength={50}
                    onChange={(e) => setCoinName(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="coinSymbol"
                    label="Coin Symbol"
                    type="text"
                    value={coinSymbol}
                    placeholder="Coin Symbol"
                    maxLength={5}
                    onChange={(e) => setCoinSymbol(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="description"
                    label="Description"
                    type="textarea"
                    value={description}
                    placeholder="Description"
                    maxLength={3000}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <label htmlFor="coin-image" label="Coin Image" style={{ fontWeight: "500", color: theme.skin==="dark"?'#fff':'#2f3030' }}>
                    Coin Image</label>
                   

                  <Dropzone onDrop={handleDropChange} accept="image/*">
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="dropzone upload-zone dz-clickable"
                        style={{ width: "300px", margin: "auto", padding: "0px" }}
                      >
                        <input type="file" accept="image/*" {...getInputProps()} />
                        {!coinImage && (
                          <div className="dz-message">
                            <span className="dz-message-text">Drag and drop file</span>
                            <span className="dz-message-or">or</span>
                            <Button color="primary">SELECT</Button>
                          </div>
                        )}
                        {imagePreview && (
                          <div className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
                            <div className="dz-image">
                              <img src={imagePreview} alt="preview" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="videoFile"
                    label="Coin Video"
                    type="file"
                    accept="video/*"
                    onChange={handleVideoChange}
                  />
                  {videoPreview && (
                    <>
                      <video src={videoPreview} controls height={200} style={{ borderRadius: "5%", marginTop: "1%" }} />
                      <Button color="danger" onClick={handleVideoCancel} style={{ marginTop: "1%", display: "block" }}>
                        Cancel Video
                      </Button>
                    </>
                  )}
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="price"
                    label="Price(USD)"
                    type="number"
                    value={price}
                    placeholder="Price ($)"
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="marketCap"
                    label="Market Cap"
                    type="number"
                    value={marketCap}
                    placeholder="Market Cap"
                    onChange={(e) => setMarketCap(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="supply"
                    label="Supply"
                    type="number"
                    value={supply}
                    placeholder="Supply"
                    onChange={(e) => setSupply(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="oneHourPrice"
                    label="One Hour Price"
                    type="number"
                    value={oneHourPrice}
                    placeholder="One Hour Price"
                    onChange={(e) => setOneHourPrice(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="twentyFourHourPrice"
                    label="Twenty-Four Hour Price"
                    type="number"
                    value={twentyFourHourPrice}
                    placeholder="Twenty-Four Hour Price"
                    onChange={(e) => setTwentyFourHourPrice(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="sevenDayPrice"
                    label="Seven Day Price"
                    type="number"
                    value={sevenDayPrice}
                    placeholder="Seven Day Price"
                    onChange={(e) => setSevenDayPrice(e.target.value)}
                    required
                  />
                </Col>
                <Col sm="12" md="6">
                  <FormInput
                    id="volume"
                    label="Volume"
                    type="number"
                    value={volume}
                    placeholder="Volume"
                    onChange={(e) => setVolume(e.target.value)}
                    required
                  />
                </Col>
              </Row>
              <div style={{ margin: "3% 0 0 0", textAlign: "center" }}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>

              <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

const FormInput = ({ id, label, type, value, placeholder, onChange, required, maxLength, accept }) => (
  <div className="form-group">
    <Label htmlFor={id} className="form-label">
      {label}
    </Label>
    <div className="form-control-wrap">
      <Input
        id={id}
        name={id}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        accept={accept}
      />
    </div>
  </div>
);

export default AddCoin;
